import axios from '@/lib/axiosController';

/**
 * 메인페이지 중요뉴스 fetch API
 * @param {number} newsId
 * @returns
 */
export const getImportantArticleAPI = async (newsId) => {
  return await axios.get('/main/article', {
    params: {
      newsId,
    },
  });
};

/**
 * 메인페이지 최신뉴스 fetch API
 * @param {string} type
 * @param {number} rankSize
 * @returns
 */
export const getLatestArticleAPI = async (type, rankSize) => {
  return await axios.get('/main/article/latest', {
    params: {
      type,
      rankSize,
    },
  });
};

export const getArticlesOrderByViewsAPI = async (formData, body) => {
  return await axios.post(`/post/upload`, formData || {}, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * 메인페이지 인기기사 fetch API
 * @param {string} type
 * @param {number} rankSize
 * @returns
 */
export const getPopularNewsAPI = async (type, rankSize) => {
  const data = axios.get('/main/article/popularity', {
    params: {
      type,
      rankSize,
    },
  });

  return data;
};
