<template>
  <v-row class="signin-wrapper" justify="center" align="center">
    <v-col xl="6" lg="6" md="8" sm="10" cols="10">
      <page title="로그인" center-title>
        <template #main-section>
          <basic-input
            id="id"
            :text-value="loginId"
            variant="underlined"
            label="아이디(휴대전화 번호)"
            placeholder="아이디(휴대전화 번호)를 입력해주세요"
            @update:textValue="loginId = $event"
            @keyup.enter="loginHandler"
          ></basic-input>

          <basic-input
            id="password"
            :text-value="password"
            :rules="passwordRules"
            :prepend-icon="mdiLock"
            variant="underlined"
            type="password"
            label="비밀번호"
            placeholder="4글자 이상"
            @update:textValue="password = $event"
            @keyup.enter="loginHandler"
          ></basic-input>
          <v-btn class="login-button" size="x-large" flat block @click="loginHandler">로그인</v-btn>
          <div class="sub-link__wrapper">
            <!--            <v-btn class="sub-link__text" :append-icon="mdiChevronRight" flat size="x-large" to="#/find-pw">비밀번호 재설정</v-btn>-->
            <v-btn class="sub-link__text pa-0 width-100 text-right" :append-icon="mdiChevronRight" flat size="x-large" to="/signup">회원가입</v-btn>
          </div>
        </template>
        <template #bottom-section>
          <div class="product-mall-wrapper">
            <product-mall-redirect-card />
          </div>
        </template>
      </page>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from 'vue';
import { mdiChevronRight } from '@mdi/js';
import Page from '@/components/Page.vue';
import BasicInput from '@/components/BasicInput.vue';
import ProductMallRedirectCard from '@/components/ProductMallRedirectCard.vue';
import { useUserStore } from '@/stores';

export default {
  components: { Page, BasicInput, ProductMallRedirectCard },
  setup() {
    // TODO (HYHP) 220827 추후에 loginId, password에 정규식 적용 필요
    const loginId = ref('');
    const password = ref('');
    const passwordRules = [(v) => !!v || '비밀번호를 입력해주세요.'];
    const { login } = useUserStore();
    const loginHandler = async () => {
      await login({ loginId: loginId.value, password: password.value });
    };
    return {
      mdiChevronRight,
      loginId,
      password,
      passwordRules,
      loginHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.signin-wrapper {
  padding: 3rem 0;
  height: 100%;
}

.login-button {
  color: #f3f3f3;
  background-color: $blue-50;
}

.sub-link {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__text {
    color: $blue-50;
  }
}

.product-mall-wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
</style>
