<template>
  <article class="root-wrapper" @click="router.push(`/news-detail/${newsId}`)">
    <v-img :src="src" width="100%" alt="thumnail" class="image-summary-row__img" />
    <div class="wrapper">
      <h5 class="image-summary-row__title">{{ title }}</h5>
      <p class="image-summary-row__desc">{{ bodySummary }}</p>
    </div>
  </article>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    src: {
      type: String,
      default: '@/assets/logo.svg',
    },
    newsId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    bodySummary: {
      type: String,
      default: '',
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style lang="scss" scoped>
// 로컬 변수
$visible-lines-mobile: 2;
$visible-lines-pc: 2;

.root-wrapper {
  display: flex;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;

  @include md-and-up {
    margin-top: 16px;
  }
}

.wrapper {
  min-width: 0;
}

.image-summary-row {
  &__img:deep {
    aspect-ratio: 1 / 1;
    max-width: 88px;
    margin-right: 8px;

    @include md-and-up {
      max-width: 108px;
      margin-right: 16px;
    }

    img {
      object-fit: cover;
    }
  }

  &__title {
    @include spreadTypoMap($title-xxsmall);
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;

    @include sm-and-up {
      width: 450px;
    }

    @include md-and-up {
      @include spreadTypoMap($title-xsmall);
      margin-bottom: 16px;
      width: 100%;
    }
  }

  &__desc {
    @include spreadTypoMap($body-xxsmall);
    color: $gray-50;
    max-height: calc(#{map-get($body-xxsmall, 'font-size')} * #{map-get($body-xxsmall, 'line-height')} * #{$visible-lines-mobile});
    -webkit-line-clamp: $visible-lines-mobile;
    margin-bottom: 0;

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: $gray-50;
      max-height: calc(#{map-get($body-medium, 'font-size')} * #{map-get($body-medium, 'line-height')} * #{$visible-lines-pc});
      -webkit-line-clamp: $visible-lines-pc;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
</style>
