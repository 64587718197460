const branchMappedKo = {
  SEOUL: {
    id: 'SEOUL',
    label: '서울',
  },
  BUSAN: {
    id: 'BUSAN',
    label: '부산',
  },
  DAEGU: {
    id: 'DAEGU',
    label: '대구',
  },
  INCHEON: {
    id: 'INCHEON',
    label: '인천',
  },
  GWANGJU: {
    id: 'GWANGJU',
    label: '광주',
  },
  DAEJEON: {
    id: 'DAEJEON',
    label: '대전',
  },
  SEJONG: {
    id: 'SEJONG',
    label: '세종',
  },
  GYEONGSANG_NORTH: {
    id: 'GYEONGSANG_NORTH',
    label: '경북',
  },
  GYEONGSANG_SOUTH: {
    id: 'GYEONGSANG_SOUTH',
    label: '경남',
  },
  ULSAN: {
    id: 'ULSAN',
    label: '울산',
  },
  GYEONGGI: {
    id: 'GYEONGGI',
    label: '경기',
  },
  GYEONGGI_NORTH: {
    id: 'GYEONGGI_NORTH',
    label: '경기북부',
  },
  GANGWON: {
    id: 'GANGWON',
    label: '강원',
  },
  CHUNGCHEONG_SOUTH: {
    id: 'CHUNGCHEONG_SOUTH',
    label: '충남',
  },
  CHUNGCHEONG_NORTH: {
    id: 'CHUNGCHEONG_NORTH',
    label: '충북',
  },
  JEOLLA_SOUTHWEST: {
    id: 'JEOLLA_SOUTHWEST',
    label: '전남서부',
  },
  JEOLLA_SOUTHEAST: {
    id: 'JEOLLA_SOUTHEAST',
    label: '전남동부',
  },
  JEOLLA_NORTH: {
    id: 'JEOLLA_NORTH',
    label: '전북',
  },
  JEJU: {
    id: 'JEJU',
    label: '제주',
  },
  SUWON: {
    id: 'SUWON',
    label: '수원특례시',
  },
  YONGIN: {
    id: 'YONGIN',
    label: '용인특례시',
  },
  GOYANG: {
    id: 'GOYANG',
    label: '고양특례시',
  },
  CHANGWON: {
    id: 'CHANGWON',
    label: '창원특례시',
  },
};

export { branchMappedKo };
