<template>
  <header>
    <GNB-menu-mobile v-model="sidebar" @change="sidebar = $event"></GNB-menu-mobile>
    <v-hover v-slot="{ isHovering, props }">
      <v-app-bar
        app
        :class="{ 'app-bar-animation': isHovering }"
        :color="isHovering ? '' : 'transparent'"
        class="gnb d-flex justify-center align-center px-0"
        height="90"
        elevation="0"
        absolute
      >
        <GNB-row>
          <!-- 로고-->
          <template #left>
            <a href="/">
              <v-img width="100" alt="koba-logo" src="@/assets/logo.svg" />
            </a>
          </template>

          <template #mobile-center>
            <div class="gnb__mobile-title">{{ pageTitle }}</div>
          </template>

          <!-- GNB 메뉴 -->
          <template #center>
            <ul class="gnb__menu" v-bind="props">
              <template v-for="item in menuStore.getMenu" :key="item.id">
                <v-hover v-slot="{ isHovering: listHover, props: listProps }">
                  <li class="gnb__item" :class="{ 'gnb__item-active': listHover }" v-bind="listProps">
                    <a :href="item.path" class="gnb__item__text" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }">{{
                      item.name
                    }}</a>
                  </li>
                </v-hover>
              </template>
              <GNB-menu-pc :is-hover="isHovering"></GNB-menu-pc>
            </ul>
          </template>
          <!-- 로그인 / 회원가입 버튼 -->
          <template v-if="userStore.getUserView?.id" #right>
            <div class="gnb__item__text-clickable" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }" @click="handleLogout">
              로그아웃
            </div>
          </template>
          <template v-else #right>
            <a class="gnb__item__text-clickable mr-4" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }" href="/signin">
              로그인
            </a>
            <a class="gnb__item__text-clickable" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }" href="/signup"> 회원가입 </a>
          </template>

          <template #mobile-right>
            <!-- 모바일 푸시 알림 리스트 (login user only) -->
            <alarm-vue v-if="userStore.getUserView?.id" :fill="menuColor"></alarm-vue>
            <!-- 모바일 GNB 버튼-->
            <v-btn class="gnb__mobile gnb__mobile__btn" flat @click="sidebarToggle">
              <menu-vue :fill="menuColor" width="32"></menu-vue>
            </v-btn>
          </template>
        </GNB-row>
      </v-app-bar>
    </v-hover>
  </header>
</template>

<script>
import { computed, ref, toRef } from 'vue';
import GNBMenuPc from './GNBMenuPc.vue';
import GNBRow from '@/components/GNBRow.vue';
import GNBMenuMobile from '@/components/GNBMenuMobile.vue';

import MenuVue from '@/icons/Menu.vue';
import { useMenuStore, useUserStore } from '@/stores';
import { useRouter } from 'vue-router';
import AlarmVue from '@/components/Alarm.vue';

export default {
  name: 'Navbar',
  components: {
    GNBMenuPc,
    GNBRow,
    GNBMenuMobile,
    MenuVue,
    AlarmVue,
  },
  props: {
    isGnbTransparent: {
      type: Boolean,
      default: false,
    },
    pageTitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const sidebar = ref(false);
    const isGnbTransparent = toRef(props, 'isGnbTransparent');
    const menuColor = computed(() => (isGnbTransparent.value ? '#e5e7eb' : ''));

    const sidebarToggle = () => {
      sidebar.value = !sidebar.value;
    };

    const userStore = useUserStore();
    const menuStore = useMenuStore();
    const router = useRouter();
    const handleLogout = () => {
      userStore.logout();
      router.push('/');
    };

    return {
      menuStore,
      sidebar,
      sidebarToggle,
      menuColor,
      userStore,
      handleLogout,
    };
  },
};
</script>

<style scoped lang="scss">
@keyframes backgroud-color-fadein {
  from {
    background-color: transparent;
  }
  to {
    background-color: white;
  }
}

.app-bar-animation {
  animation: backgroud-color-fadein 0.35s;
}

.gnb {
  overflow: unset !important;
  height: 90px;
  justify-content: space-around;

  &__mobile {
    &__btn {
      &-black {
        filter: brightness(0) saturate(100%) invert(8%) sepia(11%) saturate(2583%) hue-rotate(185deg) brightness(95%) contrast(91%); //$black
      }

      @include lg-and-up {
        &-black {
          filter: brightness(0) saturate(100%) invert(8%) sepia(11%) saturate(2583%) hue-rotate(185deg) brightness(95%) contrast(91%); //$black
        }
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.gnb:deep(.v-toolbar__content) {
  padding: 0 !important;
}

.gnb__item {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  line-height: 16px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  width: calc(100% - 10 * 4rem);
  height: 100%;

  &__text {
    @include spreadTypoMap($caption-large);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $black;

    @include md-and-down {
      @include spreadTypoMap($caption-medium);
      color: $black;
    }

    &-link {
      color: $gray-30 !important;
    }

    &-clickable {
      @include spreadTypoMap($body-small);
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.gnb__item-active {
  border-bottom: 4px solid $blue-50;
  color: $black;
}

.text__active {
  color: $gray-80 !important;
}

.gnb__mobile-title {
  @include spreadTypoMap($title-xxsmall);
  font-weight: 500;
  line-height: 19px;

  color: $gray-80;
  word-break: keep-all;
}
</style>
