<template>
  <h2 class="section__title">중앙회 일정</h2>
  <calendar ref="calendarRef" :from-page="fromPage" is-expanded :attributes="attributes" @update:from-page="getScheduleList">
    <template #day-popover="{ day, format, attributes }">
      <main-calendar-popover :day="day" :format="format" :attributes="attributes" />
    </template>
  </calendar>
</template>

<script>
import { ref } from 'vue';
import { Calendar } from 'v-calendar';

import MainCalendarPopover from '@/containers/Main/MainCalendarPopover';

import { getScheduleListAPI } from '@/apis/schedule';
import { getKrDate, parseKoFullDate } from '@/utils/date';
import 'v-calendar/dist/style.css';

export default {
  name: 'MainCalendar',
  components: { Calendar, MainCalendarPopover },
  setup() {
    const calendarProps = useCalendar();

    return { ...calendarProps };
  },
};

const getToday = () => {
  const today = getKrDate();
  const currentDate = {
    year: today.year(),
    month: today.month() + 1,
  };

  return { today, currentDate };
};

const getAttributeDate = (startDate, endDate) => {
  return { start: getKrDate(startDate).toDate(), end: getKrDate(endDate).toDate() };
};

const COLOR_PALETTE = ['blue', 'teal', 'indigo', 'green', 'gray'];

const useCalendar = () => {
  const { currentDate } = getToday();

  const calendarRef = ref(null);
  const attributes = ref([]);

  const getScheduleList = async ({ year, month }) => {
    const res = (await getScheduleListAPI({ year, month })) || [];
    const scheduleAttributes = res.map((item) => {
      const dates = getAttributeDate(item.startDate, item.endDate);
      const color = COLOR_PALETTE[item.id % 5];

      return {
        key: item.id,
        highlight: {
          start: { fillMode: 'solid', color },
          base: { fillMode: 'light', color },
          end: { fillMode: 'solid', color },
        },
        dates,
        customData: { title: item.title, description: item.description },
        popover: true,
      };
    });

    attributes.value = scheduleAttributes;
  };

  return { calendarRef, fromPage: currentDate, attributes, getScheduleList, parseKoFullDate };
};
</script>

<style scoped lang="scss">
.section__title {
  @include spreadTypoMap($title-small);
  color: $gray-20;
  margin-bottom: 2rem;

  @include md-and-up {
    margin-top: 24px;
    @include spreadTypoMap($title-large);
  }
}
</style>
