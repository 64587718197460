<template>
  <page title="산하위원회">
    <template #main-section>
      <tab2 v-model="selectedTab" is-customized-tab-action :tabs="tabs" :tab-action="tabAction" is-full-width />
      <v-window v-model="selectedTab">
        <v-window-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
          <!--          <div class="row-wrapper">-->
          <!--            <h3 class="search-result">위원회 명단</h3>-->
          <!--            <div class="select-box-wrapper">-->
          <!--              <span class="label">기수</span>-->
          <!--              <select-box :options="selectOptions" icon-color="#181E2D" />-->
          <!--            </div>-->
          <!--          </div>-->
          <component :is="CommitteeTable" :key="tab.id" :table-headers="tab.tableHeaders" :committee="tab.committee" />
        </v-window-item>
      </v-window>
    </template>
  </page>
</template>

<script>
import { computed, ref, watch } from 'vue';
import Page from '@/components/Page';
import Tab2 from '@/components/Tab2';
import SelectBox from '@/components/SelectBox';
import CommitteeTable from '@/containers/Committee/CommitteeTable';
import { tabs, selectOptions } from '@/constants/committee';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'Committee',
  components: {
    Page,
    Tab2,
    SelectBox,
    CommitteeTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = computed(() => route.params.committeeId); // read parameter id (it is reactive)

    const selectedTab = ref(id);
    watch(selectedTab, (cur, prev) => {});

    const tabAction = (path) => {
      router.push(path);
    };

    return {
      tabs,
      selectOptions,
      selectedTab,
      CommitteeTable,
      tabAction,
    };
  },
};
</script>

<style scoped lang="scss">
.row-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 0 20px;

  @include md-and-up {
    padding: 0;
  }
}

.search-result {
  margin-bottom: 0;
  @include spreadTypoMap($title-xsmall);

  @include md-and-up {
    @include spreadTypoMap($title-small);
  }
}

.select-box-wrapper:deep {
  display: flex;
  justify-content: center;
  align-items: center;

  .select-box__selected {
    background: white;
    border: 1px solid $gray-30;
    border-radius: 3px;
    padding: 9px 9px 9px 12px;
    min-width: 133px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include spreadTypoMap($button-xsmall);

    svg {
      width: 18px;
      height: 18px;
    }

    @include md-and-up {
      @include spreadTypoMap($button-medium);
      min-width: 177px;
      border-radius: 4px;
      padding: 12px 12px 12px 18px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .select-box__options {
    @include spreadTypoMap($button-xsmall);
    li {
      padding: 9px 9px 9px 12px;
    }
    @include md-and-up {
      @include spreadTypoMap($button-medium);
      li {
        padding: 12px 12px 12px 18px;
      }
    }
  }
}

.label {
  display: none;

  @include md-and-up {
    display: block;
    @include spreadTypoMap($body-medium);
    color: $gray-50;
    margin-right: 24px;
  }
}
</style>
