<template>
  <div class="p-4">
    <v-img alt="organization" src="@/assets/organization.svg"></v-img>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
