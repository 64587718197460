<template>
  <page title="대한미용사회중앙회 소개">
    <template #main-section>
      <tab2 v-model="selectedTab" :tabs="tabs" is-customized-tab-action :tab-action="tabAction" is-full-width />
      <v-window v-model="selectedTab" class="main-wrapper">
        <v-window-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
          <component :is="tab.components" :key="tab.id" />
        </v-window-item>
      </v-window>
    </template>
  </page>
</template>

<script>
import { computed, ref } from 'vue';

import Page from '@/components/Page';
import Tab2 from '@/components/Tab2';
import PresidentGrettings from '@/containers/About/PresidentGreetings.vue';
import Introduce from '@/containers/About/Introduce';
import Directions from '@/containers/About/Directions';
import History from '@/containers/About/History';
import Organization from '@/containers/About/Organization';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: { Page, Tab2 },
  setup() {
    const tabs = [
      { id: '1', name: '회장 인사말', components: PresidentGrettings },
      { id: '2', name: '협회소개', components: Introduce },
      { id: 'history', name: '연혁', components: History },
      { id: 'organization', name: '조직도', components: Organization },
      { id: 'way', name: '오시는길', components: Directions },
    ];

    const route = useRoute();
    const router = useRouter();

    const id = computed(() => route.params.aboutId); // read parameter id (it is reactive)

    const selectedTab = ref(id);

    const tabAction = (path) => {
      router.push(path);
    };

    return { selectedTab, tabs, tabAction };
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>
