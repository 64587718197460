<template>
  <div class="wrapper">
    <v-breadcrumbs class="px-0" :items="breadcrumbs"></v-breadcrumbs>
    <v-row>
      <v-col>
        <h1 class="title">{{ postData?.title }}</h1>
      </v-col>
    </v-row>
    <v-row class="meta-row" justify="space-between">
      <v-col class="meta-row__col">
        <span>
          <v-icon>{{ mdiAccount }}</v-icon> {{ postData?.userName }}
        </span>

        <div class="meta-row__col d-flex justify-end">
          <span>
            <v-icon class="mr-1">{{ mdiCalendarMonth }}</v-icon>
            <span>{{ postData?.createdDate }}</span>
          </span>
          <span>|</span>
          <span>
            <v-icon class="mr-1">{{ mdiEyePlus }}</v-icon>
            <span>{{ postData?.views }}</span>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-divider thickness="2"></v-divider>
    <div class="viewer-wrapper">
      <post-viewer ref="viewerRef" :content="postData?.body"></post-viewer>
    </div>

    <template v-if="userStore.getUserView.id === postData?.userId">
      <v-divider />

      <div class="post-action__group">
        <v-btn class="post-action__btn" flat @click="editPost">수정</v-btn>
        <v-btn class="post-action__btn" flat @click="showDeletePostConfirmDialog">삭제</v-btn>
      </div>
    </template>

    <v-divider thickness="2"></v-divider>

    <!--  첨부 리스트 -->
    <div class="p-2 my-3">
      <h6>첨부 파일</h6>
      <div v-for="file of postData?.postFileViewList" :key="file.id" class="row py-2 ps-1 mb-2 border rounded-3">
        <div class="col-auto">
          <span>
            <v-icon color="#6C737F" class="mr-2">{{ mdiCloudDownload }}</v-icon>
            <a style="font-size: 14px" :href="file.url"> {{ file.fileName }} </a>
          </span>
        </div>
      </div>
      <div v-if="!postData?.postFileViewList || postData?.postFileViewList.length === 0" class="text-center">등록된 첨부파일이 없습니다.</div>
    </div>

    <!-- 댓글 리스트 -->
    <div class="p-2 my-12">
      <div v-for="item in comments || []" :key="item.id">
        <v-row>
          <v-col class="comment__row">
            <div class="comment__header">
              <span class="comment__writer">{{ item.userName }}</span>
              <span v-if="!!item?.createdDate" class="text__body-small"> {{ parseAgoDate(item.createdDate) }}</span>
            </div>

            <!--            comments action-->
            <div v-if="userStore.getUserView.id === item.userId" class="comment__action">
              <button @click="clickEditBtn(item.id, item.content)">수정</button>
              <button @click="showDeleteCommentConfirmDialog(item.id)">삭제</button>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="p-4">
            <div class="text__body-small">{{ item.content }}</div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </div>

    <!-- 댓글 작성-->
    <div>
      <div class="text__body-large mb-4">{{ commentsLength }}개의 댓글</div>
      <v-textarea
        v-model="comment"
        :disabled="!userStore.getUserView?.id"
        :label="!!userStore.getUserView?.id ? '댓글을 적어주세요' : '댓글을 작성하려면 로그인이 필요합니다.'"
        placeholder="권리침해, 욕설 및 특정 대상을 비하하는 내용을 게시할 경우 이용약관 및 관련법률에 의해 제제될 수 있습니다. 공공기기에서는 사용 후 로그아웃 해주세요."
        maxlength="400"
        full-width
        counter
      ></v-textarea>
    </div>
    <div v-if="!!userStore.getUserView?.id" class="post-btn__wrapper">
      <v-btn v-if="commentAction === CREATE" class="post-btn" flat @click="addComment">등록</v-btn>
      <v-btn v-if="commentAction === EDIT" class="post-btn" flat @click="updateComment">수정</v-btn>
      <v-btn v-if="commentAction === EDIT" class="post-btn reply-cancel-btn" flat @click="cancelEditComment">수정 취소</v-btn>
    </div>
  </div>

  <!--  모달창-->
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-item>
        <v-card-text class="text__body-medium">
          {{ dialogMessage }}
        </v-card-text>
      </v-card-item>
      <v-card-actions style="display: flex; justify-content: flex-end">
        <v-btn variant="flat" style="background: #0070bc; color: white" @click="closeConfirmDialog">닫기</v-btn>
        <v-btn variant="flat" :loading="dialogAction.isLoading" style="background: #005289; color: white" @click="dialogAction.confirm">확인</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { mdiAccount, mdiCalendarMonth, mdiEyePlus, mdiCloudDownload } from '@mdi/js';
import PostViewer from '@/components/PostViewer.vue';
import { useUserStore } from '@/stores';
import { deletePostAPI } from '@/apis/__post__';
import { createReply, deleteReply, editReply, getReplyList } from '@/apis/__reply__';
import { successToast } from '@/utils/toast';
import { parseAgoDate } from '@/utils/date';

const downloadItem = (file) => {
  axios
    .get(file.url, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file.url.match(/(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/)[0];
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(console.error);
};

const [CREATE, EDIT] = ['CREATE', 'EDIT'];

export default {
  components: { PostViewer },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    subCategory: {
      type: Array,
      default: () => [],
    },
    postId: {
      type: String,
      default: 'aa',
    },
    postData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    postLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const selectedCategory = ref('');
    const viewerRef = ref();
    const userStore = useUserStore();

    const comments = ref([]);
    const commentsLength = ref(0);
    const comment = ref('');
    const commentAction = ref(CREATE);
    const targetCommentId = ref(null);

    const getComments = async () => {
      const res = await getReplyList(props.postId);
      comments.value = res.content;
      commentsLength.value = res.numberOfElements;
    };

    watch(
      () => props.postLoading,
      (next, old) => {
        // 게시글 로딩이 끝난 후 댓글 로딩
        if ((old && !next) || !old) {
          getComments();
        }
      },
    );

    const onClickCancel = () => {
      router.back();
    };

    const onClickPost = () => {
      router.back();
    };

    const updateBody = (body) => {
      viewerRef.value.updateBody(body);
    };

    const resetComment = () => {
      comment.value = '';
      commentAction.value = CREATE;
      targetCommentId.value = null;
    };

    const addComment = async () => {
      await createReply({ postId: props.postId, content: comment.value });
      successToast('댓글이 추가되었습니다.');
      comment.value = '';

      await getComments();
    };

    const clickEditBtn = (commentId, targetComment) => {
      targetCommentId.value = commentId;
      comment.value = targetComment;
      commentAction.value = EDIT;
    };

    const cancelEditComment = () => {
      resetComment();
    };

    const updateComment = async () => {
      await editReply({ replyId: targetCommentId.value, content: comment.value });
      successToast('댓글이 수정되었습니다.');

      resetComment();
      await getComments();
    };

    const deleteComment = async (commentId) => {
      try {
        dialogAction.value.isLoading = true;

        await deleteReply(commentId);
        successToast('댓글이 삭제되었습니다.');
      } finally {
        dialogAction.value.isLoading = false;
        closeConfirmDialog();

        getComments();
      }
    };

    /** 포스트 수정 & 삭제 */
    const editPost = () => {
      const isSecurePost = route.query.type === 'secure';
      const query = isSecurePost ? '&type=secure' : '';
      router.push(`/write?postId=${props.postId}${query}`);
    };

    const deletePost = async () => {
      dialogAction.value.isLoading = true;
      try {
        await deletePostAPI(props.postId);
        successToast('게시글을 삭제했습니다.');

        router.back();
      } finally {
        dialogAction.value.isLoading = false;
        closeConfirmDialog();
      }
    };

    const dialog = ref(false);
    const dialogMessage = ref('');
    const dialogAction = ref({
      confirm: () => {},
      isLoading: false,
    });

    const closeConfirmDialog = () => {
      dialog.value = false;
      dialogMessage.value = '';
      dialogAction.value = {
        confirm: () => {},
        isLoading: false,
      };
    };

    const showConfirmDialog = ({ message, confirm }) => {
      dialog.value = true;
      dialogMessage.value = message;
      dialogAction.value = {
        confirm,
        isLoading: false,
      };
    };

    const showDeletePostConfirmDialog = () => {
      showConfirmDialog({ message: '정말로 게시글을 삭제하시겠습니까?', confirm: deletePost });
    };

    const showDeleteCommentConfirmDialog = (commentId) => {
      showConfirmDialog({ message: '정말로 댓글을 삭제하시겠습니까?', confirm: () => deleteComment(commentId) });
    };

    return {
      selectedCategory,
      comments,
      commentsLength,
      comment,
      commentAction,
      clickEditBtn,
      onClickCancel,
      onClickPost,
      mdiAccount,
      mdiCalendarMonth,
      mdiEyePlus,
      viewerRef,
      updateBody,
      downloadItem,
      mdiCloudDownload,
      userStore,
      parseAgoDate,
      addComment,
      updateComment,
      cancelEditComment,
      deleteComment,
      CREATE,
      EDIT,
      editPost,
      deletePost,
      dialog,
      dialogMessage,
      dialogAction,
      showDeletePostConfirmDialog,
      closeConfirmDialog,
      showDeleteCommentConfirmDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 1.5rem;

  @include sm-and-down {
    padding: 1rem;
  }
}

.title {
  @include spreadTypoMap($title-large);

  @include sm-and-down {
    @include spreadTypoMap($title-medium);
  }
}

.meta-row {
  &__col {
    display: flex;
    justify-content: space-between;

    @include spreadTypoMap($body-small);

    & > span,
    :deep(.v-breadcrumbs-item) {
      display: flex;
      color: $gray-50;
    }

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.viewer-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;

  padding: 1rem;
}

.cancel-btn {
  border: 1px solid $gray-30;
  margin-right: 0.5rem;
}

.post-btn {
  background-color: $blue-50;
  color: $gray-10;

  &__wrapper {
    display: flex;
    justify-content: end;
  }
}

.post-action {
  &__group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__btn {
    background: $gray-10;
    border-radius: 4px;
    padding: 4px 8px;
    border: 1px solid $gray-20;

    @include spreadTypoMap($button-xsmall);
    color: $gray-50;

    & + & {
      margin-left: 0.5rem;
    }
  }
}

.comment {
  &__writer {
    @include spreadTypoMap($body-medium);
    color: $blue-50;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div > span:not(:last-child) {
      margin-right: 0.5rem;
    }

    @include md-and-up {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include md-and-up {
      display: block;
    }
  }

  &__action {
    align-self: flex-end;

    button {
      background: $gray-10;
      border-radius: 4px;
      padding: 4px 8px;
      border: 1px solid $gray-20;

      @include spreadTypoMap($button-xxsmall);
      color: $gray-50;
    }

    button + button {
      margin-left: 0.5rem;
    }
  }
}

.reply-cancel-btn {
  margin-left: 0.5rem;
  background: white;
  border: 1px solid $blue-50;
  color: $blue-50;
}
</style>
