<template>
  <page :doc-title="newsDetail?.title || ''">
    <template #main-section>
      <div class="root-wrapper">
        <header>
          <h2 class="news-title">{{ newsDetail?.title }}</h2>
          <div class="news-header-info">
            <span class="header-info__author header-info"
              ><v-icon>{{ mdiAccountOutline }}</v-icon
              >{{ newsDetail?.reporterView?.reporterName }}
            </span>
            <span class="header-info__date header-info"
              ><v-icon>{{ mdiClock }}</v-icon
              >승인 {{ newsDetail?.createdDate }}</span
            >
          </div>
        </header>
        <hr />

        <section style="padding: 8px 0">
          <component :is="newsDetail?.view" />
        </section>

        <div id="news-body" v-html="newsDetail?.body"></div>

        <footer style="padding: 8px 0">
          <div v-if="newsDetail?.tags?.length > 0" class="tag__wrapper">
            <div class="tag__label">Tag</div>
            <div>
              <span v-for="tag in newsDetail?.tags || []" :key="tag" class="tag__item">{{ tag }}</span>
            </div>
          </div>
          <div class="copyright">저작권자 © 대한미용사회중앙회 무단전재 및 재배포 금지</div>
          <div class="profile__wrapper">
            <img class="profile__image" src="/assets/user.png" alt="" />
            <div class="profile__name">{{ newsDetail?.reporterView?.reporterName }}</div>
          </div>
        </footer>
        <hr style="margin-bottom: 48px" />
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page';
import { useRoute } from 'vue-router';
import { mdiAccountOutline, mdiClock } from '@mdi/js';
import { getArticle } from '@/apis/article';
import { onMounted, onUnmounted, ref } from 'vue';

export default {
  name: 'NewsDetail',
  components: {
    Page,
  },
  setup() {
    const route = useRoute();
    const newsId = route.params.newsId;
    const newsDetail = ref({});
    onMounted(() => {
      const style = document.createElement('link');
      style.type = 'text/css';
      style.rel = 'stylesheet';
      style.href = '/css/legacyNewsArticle.css';
      style.id = 'news-detail-legacy';
      document.head.appendChild(style);
    });

    onUnmounted(() => {
      const element = document.head.querySelector('#news-detail-legacy');
      if (element) {
        document.head.removeChild(element);
      }
    });

    const fetchAPI = async () => {
      const result = await getArticle(newsId);
      newsDetail.value = {
        ...result,
        body: result?.body?.replaceAll('src="/', 'src="http://www.ko-ba.org/'),
      };
    };
    fetchAPI();

    onMounted(() => {
      fetchAPI().then();
    });

    return { newsDetail, mdiAccountOutline, mdiClock };
  },
};
</script>

<style scoped lang="scss">
.root-wrapper {
  padding: 20px;
  margin-top: 24px;

  @include md-and-up {
    padding: 0;
    margin-top: 48px;
  }
}

.news-title {
  @include spreadTypoMap($title-small);
  line-height: 120%;

  @include md-and-up {
    @include spreadTypoMap($title-large);
  }
}

.news-header-info {
  @include spreadTypoMap($caption-medium);
  display: flex;
  margin-top: 24px;

  .header-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-info__date:before {
    padding: 0 8px;
    content: '|';
    color: #a0a6b1;
  }

  i {
    margin-right: 4px;
    width: 19px;
  }
}

.copyright {
  margin: 24px 0;
  @include spreadTypoMap($caption-medium);
  color: rgba(0, 0, 0, 0.75);
}

.profile {
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image {
    display: block;
    margin-right: 8px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid $gray-30;
  }

  &__name {
    @include spreadTypoMap($body-medium);
  }
}

.tag {
  &__wrapper {
    display: flex;
  }

  &__label {
    position: relative;
    padding: 0.3rem 0.625rem;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1em;
    color: #fefefe;
    background: $blue-50;
    border-radius: 0.15rem;
    margin-right: 16px;
    height: fit-content;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      border-width: 4px;
      border-style: solid;
      border-color: transparent transparent transparent $blue-50;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__item {
    color: $blue-50;
    cursor: pointer;

    & + & {
      margin-left: 8px;
    }
  }
}

#news-body:deep {
  .article-head-sub {
    border-left: 3px solid black;
    padding: 4px 0 4px 8px;
  }

  .tag-group {
    display: none;
  }

  .view-copyright {
    display: none;
  }

  .view-editors {
    display: none;
  }

  img {
    @include md-and-down {
      width: 100% !important;
      height: auto !important;
      object-fit: scale-down !important;
      max-height: 250px !important;
    }
  }

  figure {
    @include md-and-down {
      float: none !important;
      margin: 0 !important;
    }

    figcaption {
      text-align: center !important;
    }
  }
}
</style>
