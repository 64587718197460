<template>
  <page title="자료&커뮤니티">
    <template #main-section>
      <tab2 v-model="selectedTab" :tabs="menuStore.getMenuMap[16]" is-full-width />
      <div class="board-pc">
        <component
          :is="tabs[selectedTab].pc"
          :key="tabs[selectedTab].id"
          ref="boardPcRef"
          :on-page-change="onPageChange"
          :pages="pages"
          :selected-options="selectOptions"
          :table-headers="tabs[selectedTab].tableHeaders"
          :posts="communityList"
        />
      </div>
      <div class="board-mobile">
        <component
          :is="tabs[selectedTab].mobile"
          :key="tabs[selectedTab].id"
          ref="boardMobileRef"
          :on-page-change="onPageChange"
          :pages="pages"
          :selected-options="selectOptions"
          :posts="communityListMobile"
          :is-finished="isFinished"
        />
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page';
import Tab2 from '@/components/Tab2';
import BoardPc from '@/containers/Community/BoardPc';
import BoardMobile from '@/containers/Community/BoardMobile';

import UsedMarketBoardPc from '@/containers/Community/UsedMarket/BoardPc.vue';
import UsedMarketBoardMobile from '@/containers/Community/UsedMarket/BoardMobile.vue';

import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { searchPostListAPI } from '@/apis/__post__';
import { getAllUsedMarketPostAPI } from '@/apis/usedMarket';
import { useMenuStore } from '@/stores';

const selectOptions = [
  { id: 'all', label: '전체' },
  { id: 'title', label: '제목' },
  { id: 'author', label: '작성자' },
];

const tableHeaders = [
  { id: 'number', label: '번호' },
  { id: 'title', label: '제목' },
  { id: 'nickname', label: '닉네임' },
  { id: 'date', label: '작성일' },
  { id: 'view', label: '조회수' },
];

const defaultTab = {
  pc: BoardPc,
  mobile: BoardMobile,
  tableHeaders,
  searchPostListHandler: searchPostListAPI,
};

const tabs = {
  11: {
    id: '규정',
    ...defaultTab,
  },
  12: {
    id: '협회양식',
    ...defaultTab,
  },
  13: {
    id: '공지사항',
    ...defaultTab,
  },
  14: {
    id: 'Q&A',
    ...defaultTab,
  },
  16: {
    id: '세미나 일정',
    ...defaultTab,
  },
  17: {
    id: '구인구직',
    ...defaultTab,
  },
  18: {
    id: '관련단체',
    ...defaultTab,
  },
  19: {
    id: '지회지부',
    ...defaultTab,
  },
  20: {
    id: '중고매매',
    pc: UsedMarketBoardPc,
    mobile: UsedMarketBoardMobile,
    tableHeaders: [
      { id: 'number', label: '번호' },
      { id: 'title', label: '제목' },
      { id: 'status', label: '판매상태' },
      { id: 'nickname', label: '닉네임' },
      { id: 'date', label: '작성일' },
      { id: 'view', label: '조회수' },
    ],
    searchPostListHandler: getAllUsedMarketPostAPI,
  },
};

export default {
  name: 'Community',
  components: {
    Page,
    Tab2,
    BoardPc,
    BoardMobile,
  },
  setup() {
    const route = useRoute();
    const pages = ref(0);
    const isFinished = ref(false);
    const boardPcRef = ref(null);
    const boardMobileRef = ref(null);
    const boardId = Number(route.params.communityId); // read parameter id (it is reactive)
    const communityList = ref([]);
    const communityListMobile = ref([]);
    const menuStore = useMenuStore();
    const selectedTab = ref(boardId);

    const setNewsValues = (e, isInit) => {
      pages.value = e?.totalPages;
      isFinished.value = e?.last;
      communityList.value = e?.content;
      if (isInit) {
        communityListMobile.value = e?.content;
        boardPcRef.value.initPage();
        boardMobileRef.value.initPage();
      } else {
        communityListMobile.value = communityListMobile.value.concat(e?.content);
      }
    };

    const onPageChange = async (page) => {
      const data = await tabs[selectedTab.value].searchPostListHandler({ size: 10, page: page - 1, boardId: selectedTab.value });
      setNewsValues(data);
    };

    watch(selectedTab, async (next) => {
      const apiFn = tabs[selectedTab.value] ? tabs[selectedTab.value].searchPostListHandler : Object.values(tabs)[0].searchPostListHandler;
      const ret = await apiFn({ size: 10, page: 0, boardId: next });
      setNewsValues(ret, true);
    });

    onMounted(() => {
      onPageChange(1);
    });

    return {
      menuStore,
      selectOptions,
      communityList,
      communityListMobile,
      tableHeaders,
      selectedTab,
      pages,
      tabs,
      defaultTab,
      isFinished,
      onPageChange,
      boardPcRef,
      boardMobileRef,
    };
  },
};
</script>

<style scoped lang="scss">
// board pc & mobile
.board-pc {
  display: none;

  @include md-and-up {
    display: block;
  }
}

.board-mobile {
  display: block;
  margin-top: 32px;
  padding: 0 20px 48px;

  @include md-and-up {
    display: none;
  }
}
</style>
