<template>
  <v-container class="form">
    <v-row justify="center">
      <v-col>
        <v-form ref="validRef" v-model="isValidForm" lazy-validation>
          <basic-input
            id="email"
            label="이메일"
            placeholder="이메일을 입력해주세요"
            :text-value="privacyInfo.email"
            :rules="validRules.email"
            required
            @update:textValue="privacyInfo.email = $event"
          />
          <basic-input id="name" label="이름" placeholder="예) 홍길동" :text-value="privacyInfo.name" required @update:textValue="privacyInfo.name = $event" />
          <basic-input
            id="id"
            label="아이디"
            placeholder="휴대전화 번호도 가능합니다"
            required
            :text-value="privacyInfo.loginId"
            @update:textValue="privacyInfo.loginId = $event"
            @keydown="resetIsUsingId"
          >
            <template #append>
              <v-btn :disabled="privacyInfo.loginId.length === 0" variant="outlined" flat size="default" @click="checkLoginIdDuplicate">중복 검사</v-btn>
            </template>

            <template #details>
              <v-spacer />
              <span v-if="isUsingId === true" class="text-able">사용 가능한 아이디입니다</span>
              <span v-if="isUsingId === false" class="text-not-able">이미 사용중인 아이디입니다</span>
            </template>
          </basic-input>
          <basic-input
            id="password1"
            label="비밀번호 설정"
            placeholder="비밀번호를 입력해주세요"
            hint="4글자 이상"
            type="password"
            :text-value="privacyInfo.password"
            :rules="validRules.password"
            persistent-hint
            required
            @update:textValue="privacyInfo.password = $event"
          />
          <basic-input
            id="password2"
            label="비밀번호 재입력"
            placeholder="다시한번 비밀번호를 입력해주세요"
            type="password"
            :text-value="privacyInfo.confirmedPassword"
            :error-messages="
              privacyInfo.confirmedPassword !== '' && privacyInfo.password !== privacyInfo.confirmedPassword ? '비밀번호가 일치하지 않습니다.' : ''
            "
            required
            @update:textValue="privacyInfo.confirmedPassword = $event"
          />

          <basic-input
            id="phone"
            label="연락처 입력"
            placeholder="연락처를 입력해주세요"
            :text-value="privacyInfo.phone"
            :rules="validRules.phone"
            :disabled="[CERTIFICATE_STATUS.pending.id, CERTIFICATE_STATUS.complete.id].includes(verifyStatus)"
            @update:textValue="privacyInfo.phone = $event"
          >
            <template #append>
              <v-btn variant="outlined" flat size="default" :disabled="privacyInfo.phone.length < 10" @click="phoneVetifyRequest">인증번호 받기</v-btn>
            </template>
          </basic-input>

          <basic-input
            id="phoneVerify"
            label="인증번호 입력"
            placeholder="인증번호를 입력해주세요"
            type="number"
            hint="핸드폰 번호를 다시 입력하려면 인증하기 버튼을 눌러주세요."
            :text-value="privacyInfo.verifyNumber"
            :rules="validRules.verifyNumber"
            :disabled="[CERTIFICATE_STATUS.none.id, CERTIFICATE_STATUS.complete.id].includes(verifyStatus)"
            @update:textValue="privacyInfo.verifyNumber = $event"
          >
            <template #append>
              <v-btn variant="outlined" flat size="default" @click="phoneVerify"> 인증하기 </v-btn>
            </template>

            <template #details>
              <div class="text__wrapper">
                <div v-if="[CERTIFICATE_STATUS.pending.id, CERTIFICATE_STATUS.reject.id].includes(verifyStatus)" class="text-not-able">
                  남은 시간: {{ phoneVerifyTimeText }}
                </div>
                <div v-if="verifyStatus === CERTIFICATE_STATUS.complete.id" class="text-able">인증이 완료되었습니다.</div>
                <div v-if="verifyStatus === CERTIFICATE_STATUS.reject.id" class="text-not-able">인증에 실패했습니다.</div>
              </div>
            </template>
          </basic-input>

          <basic-input
            id="address1"
            label="주소"
            placeholder="주소 찾기"
            :text-value="privacyInfo.address"
            :rules="validRules.address"
            readonly
            @update:textValue="privacyInfo.address = $event"
          >
            <template #append>
              <v-dialog v-model="isOpenDialog">
                <template #activator="{ on, attrs }">
                  <v-btn variant="outlined" flat size="default" v-bind="attrs" v-on="on" @click="openFindAddress">주소 찾기</v-btn>
                </template>

                <daum-postcode :on-complete="onCompleteFindAddress" />
              </v-dialog>
            </template>
          </basic-input>

          <basic-input
            id="address2"
            label="상세주소 입력"
            placeholder="상세 주소를 입력해주세요"
            :disabled="privacyInfo.address === ''"
            :text-value="privacyInfo.addressDetail"
            :rules="validRules.addressDetail"
            @update:textValue="privacyInfo.addressDetail = $event"
          />

          <div>
            <required-caption label="소속 지회, 지부 선택" required></required-caption>
            <v-row>
              <v-col>
                <v-select
                  v-model="selectedMainBranch"
                  :append-inner-icon="mdiChevronDown"
                  :items="mainBranches"
                  :menu-props="{ class: 'office-menu' }"
                  variant="underlined"
                  item-title="label"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedBranchId"
                  :append-inner-icon="mdiChevronDown"
                  :disabled="subBranches.length === 0"
                  :items="subBranches"
                  :menu-props="{ class: 'office-menu' }"
                  variant="underlined"
                  item-title="branchName"
                  item-value="id"
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <basic-input
            id="bank-account"
            label="하나은행 계좌"
            placeholder="하나은행 계좌를 입력해주세요"
            :disabled="isAuthenticatedAccount"
            :text-value="privacyInfo.accountNumber"
            @update:textValue="privacyInfo.accountNumber = $event"
          >
            <template #append>
              <v-btn :disabled="isAuthenticatedAccount" variant="outlined" flat size="default" @click="verifyAccountNumber">{{
                verificationAccountLabel
              }}</v-btn>
            </template>

            <template #details>
              <v-spacer />
              금융몰 및 지회지부 매칭영업점에서 계좌개설이 가능합니다. 하나은행 계좌를 보유하시면 자세한 금융상담 및 다양한 혜택/이벤트가 기다리고 있습니다.
            </template>
          </basic-input>

          <basic-button-group
            label="금융주치의 상담신청"
            :items="loanDesiredItems"
            :active-value="privacyInfo.isRequestedLoan"
            @update:activeValue="privacyInfo.isRequestedLoan = $event"
          >
          </basic-button-group>
        </v-form>
      </v-col>
    </v-row>

    <v-row class="mt-12" justify="center">
      <v-col>
        <v-btn class="regist-btn" flat size="x-large" block :loading="loading" @click="signUpRequest">가입하기</v-btn>
        <v-btn class="cancel-btn" flat size="x-large" block @click="goBackStep">이전</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { mdiChevronDown } from '@mdi/js';

import { checkLoginId, authSend, authVerify, verifyAccountNumberApi } from '@/apis/user';
import { getBranchOfficeList } from '@/apis/branch';

import { CERTIFICATE_STATUS, phoneAuthLimitSecond } from '@/constants/signUp';
import { branchMappedKo } from '@/constants/category';
import BasicButtonGroup from '@/components/BasicButtonGroup.vue';
import BasicInput from '@/components/BasicInput.vue';
import RequiredCaption from '@/components/RequiredCaption.vue';
import DaumPostcode from '@/components/DaumPostcode.vue';

import { errorToast, successToast } from '@/utils/toast';
import { toMMSS } from '@/utils/date';

const phoneNumberReg = /^(01[0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

export default {
  components: { BasicInput, BasicButtonGroup, RequiredCaption, DaumPostcode },
  props: {
    goBackStep: {
      type: Function,
      default: () => {},
    },
    goNextStep: {
      type: Function,
      default: () => {},
    },
    signupHandler: {
      type: Function,
      default: () => {},
    },
  },
  setup({ signupHandler }) {
    const router = useRouter();
    const loanDesiredItems = [
      { id: true, label: '희망' },
      { id: false, label: '이용 의사 없음' },
    ];

    const validRef = ref(null);

    const verifyStatus = ref(CERTIFICATE_STATUS.none.id);
    const loading = ref(false);
    const isValidForm = ref(false);
    const isUsingId = ref(null);
    const isOpenDialog = ref(false);
    const isAuthenticatedAccount = ref(false);
    const verificationAccountLabel = ref('계좌 인증');

    let timerIntervalId = '';
    const phoneVerifyTimeText = ref('');

    const branchInfos = ref({});
    const mainBranches = Object.values(branchMappedKo);
    const subBranches = ref([]);
    const selectedMainBranch = ref('');
    const selectedBranchId = ref(1);
    const daumAddressRef = ref(null);
    // const selectedBranchId = ref(0);

    const privacyInfo = ref({
      name: '',
      email: '',
      loginId: '',
      password: '',
      confirmedPassword: '',
      phone: '',
      verifyNumber: '',
      address: '',
      zip: '',
      addressDetail: '',
      accountNumber: '',
      branchId: selectedBranchId,
      isRequestedLoan: loanDesiredItems[0].id,
    });

    watch(selectedMainBranch, () => {
      const branches = branchInfos.value[selectedMainBranch.value];
      if (Array.isArray(branches) && branches.length > 0) {
        selectedBranchId.value = branches[0];
        subBranches.value = branches;
      } else {
        subBranches.value = [];
      }
    });

    const fetchApi = async () => {
      branchInfos.value = await getBranchOfficeList();
      selectedMainBranch.value = branchMappedKo.SEOUL.id;
      selectedBranchId.value = subBranches.value[0];
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      fetchApi();
    });

    const validRules = {
      name: [(v) => (v.length > 0 && v.length < 31) || '이름은 30글자 이하로 입력해주세요.'],
      email: [(v) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(v) || '올바른 형식의 이메일을 입력해주세요.'],
      password: [(v) => v.length >= 4 || '비밀번호는 4글자 이상 입력해주세요'],
      phone: [(v) => phoneNumberReg.test(v) || '올바른 휴대전화 번호가 아닙니다.'],
      verifyNumber: [(v) => v !== '' || '인증번호를 입력해주세요.'],
      accountNumber: [(v) => v !== '' || '계좌번호를 입력해주세요.'],
    };

    const openFindAddress = () => {
      isOpenDialog.value = true;
    };

    const onCompleteFindAddress = (address, zipcode) => {
      isOpenDialog.value = false;
      privacyInfo.value.address = address;
      privacyInfo.value.zip = zipcode;
    };

    const signUpRequest = async () => {
      if (!isValidForm.value || !isUsingId.value) {
        const isValidPassword =
          privacyInfo.value.password !== privacyInfo.value.confirmedPassword || privacyInfo.value.password === '' || privacyInfo.value.confirmedPassword === '';
        const needCheckFields = [
          validRules.email[0](privacyInfo.value.email) !== true && '이메일',
          validRules.name[0](privacyInfo.value.name) !== true && '이름',
          !isUsingId.value && '아이디 중복 확인',
          isValidPassword && '비밀번호',
          verifyStatus.value !== CERTIFICATE_STATUS.complete.id && '핸드폰 인증',
        ].filter((v) => v);

        errorToast(`아래 정보를 다시 확인해주세요.\n${needCheckFields.join(', ')}`);
        return;
      }

      loading.value = true;
      const value = {
        ...privacyInfo.value,
        accountNumber: isAuthenticatedAccount.value ? privacyInfo.value.accountNumber : undefined,
        branchId: selectedBranchId.value?.id ? selectedBranchId.value?.id : selectedBranchId.value,
      };
      const result = await signupHandler(value);
      loading.value = false;

      if (result) {
        router.push('/');
      } else {
        errorToast('회원가입에 실패했습니다.');
      }
    };

    const startVerifyTimer = () => {
      let currentVerifyTime = phoneAuthLimitSecond;

      if (timerIntervalId !== '') {
        clearInterval(timerIntervalId);
      }

      timerIntervalId = setInterval(() => {
        const m = parseInt(currentVerifyTime / 60);
        const s = currentVerifyTime - m * 60;
        phoneVerifyTimeText.value = toMMSS(m, s);
        currentVerifyTime -= 1;

        if (currentVerifyTime === 0) {
          clearInterval(timerIntervalId);
          phoneVerifyTimeText.value = '';
        }
      }, 1000);
    };

    const phoneVetifyRequest = async () => {
      const parsedPhone = privacyInfo.value.phone.replace(phoneNumberReg, '$1-$2-$3');

      if (privacyInfo.value.name === '') {
        errorToast('이름을 입력해주세요');
        return;
      }

      if (!phoneNumberReg.test(privacyInfo.value.phone)) {
        errorToast('올바른 휴대전화 번호가 아닙니다');
        return;
      }

      await authSend({ name: privacyInfo.value.name, phone: parsedPhone });

      successToast('인증번호를 요청했습니다.');
      startVerifyTimer();
      verifyStatus.value = CERTIFICATE_STATUS.pending.id;
    };

    const phoneVerify = async () => {
      const parsedPhone = privacyInfo.value.phone.replace(phoneNumberReg, '$1-$2-$3');

      const data = await authVerify({ name: privacyInfo.value.name, phone: parsedPhone, authCode: privacyInfo.value.verifyNumber });

      if (data.result) {
        clearInterval(timerIntervalId);
        verifyStatus.value = CERTIFICATE_STATUS.complete.id;
      } else {
        verifyStatus.value = CERTIFICATE_STATUS.reject.id;
      }
    };

    const checkLoginIdDuplicate = async () => {
      const validId = privacyInfo.value.loginId.replace(/ /g, '');
      privacyInfo.value.loginId = validId;
      const result = await checkLoginId({ loginId: validId });

      isUsingId.value = result;
    };

    const resetIsUsingId = () => {
      isUsingId.value = null;
    };

    const verifyAccountNumber = async () => {
      const result = await verifyAccountNumberApi({ name: privacyInfo.value.name, accountNumber: privacyInfo.value.accountNumber });
      if (result.result === true) {
        verificationAccountLabel.value = '인증 완료';
        isAuthenticatedAccount.value = true;
      }
    };

    return {
      validRef,
      mdiChevronDown,
      isOpenDialog,
      isValidForm,
      isUsingId,
      loading,
      isAuthenticatedAccount,
      verificationAccountLabel,
      verifyStatus,
      daumAddressRef,
      CERTIFICATE_STATUS,
      phoneVerifyTimeText,
      privacyInfo,
      selectedMainBranch,
      selectedBranchId,
      validRules,
      loanDesiredItems,
      mainBranches,
      subBranches,
      signUpRequest,
      phoneVetifyRequest,
      phoneVerify,
      openFindAddress,
      onCompleteFindAddress,
      checkLoginIdDuplicate,
      resetIsUsingId,
      verifyAccountNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.regist-btn {
  @include spreadTypoMap($button-medium);

  background: $blue-50;
  color: white;
}

.cancel-btn {
  color: $blue-50;
}

.text {
  @include spreadTypoMap($body-xsmall);

  &__wrapper {
    display: flex;
    flex-direction: column;
    text-align: end;

    & > :not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &-able {
    color: $blue-50;
  }

  &-not-able {
    color: $koba-red;
  }
}

.hint {
  @include spreadTypoMap($caption-xsmall);
  margin-bottom: 1rem;
}

.daum-address {
  width: 500px;
  height: 500px;
}

/** FIXME: (조명근) deep을 사용해서 v-select__content에 height을 주려해도 적용되지 않아 전역 css로 선언해두었음. */
.office-menu > .v-select__content {
  height: 20rem;
}
</style>
