<template>
  <div class="main-intro__wrapper">
    <v-img class="main-intro__img" alt="main-image"></v-img>
  </div>
</template>

<script>
export default {
  name: 'IntroBackground',
};
</script>

<style lang="scss" scoped>
.main-intro {
  &__wrapper {
    position: absolute;
    top: -90px;
    left: 0;
    width: 100%;
    height: 1080px;
    background-color: $black;
    z-index: -1;
  }

  &__img {
    position: absolute;
    right: 0;
    width: 1314px;
    height: 1820px;
    background: linear-gradient(to right, #181e2d 0%, rgba(24, 30, 45, 0.1) 48.46%, rgba(24, 30, 45, 0) 86.15%), url(@/assets/main-intro.png);
  }
}
</style>
