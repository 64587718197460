export const serviceTerms = [
  {
    title: '서비스 이용 약관의 동의',
    terms: [
      '대한미용사회중앙회의 서비스를 이용하여 주셔서 감사 합니다. 대한미용사회중앙회의 서비스는 아래의 이용약관을 동의 하는 것을 조건으로 하여 사용자에게 서비스를 제공 합니다.',
      '대한미용사회중앙회 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후에도 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.',
      '대한미용사회중앙회의 이용 약관은 별도의 통지 없이 수시로 변경 될 수 있으며 사용자는 언제든지 대한미용사회중앙회의 서비스 이용 약관 메뉴를 통해 새롭게 갱신된 내용을 보실 수 있습니다.',
      '대한미용사회중앙회의 서비스 이용 약관은 대한미용사회중앙회가 제공하는 모든 서비스에 적용 됩니다. 대한미용사회중앙회의 컨텐츠라 함은 대한미용사회중앙회가 서비스 하는 모든 문자, 그래픽, 모든 종류의 파일, 소프트웨어를 말합니다.',
    ],
  },
  {
    title: '대한미용사회중앙회의 서비스 및 이용자의 권한',
    terms: [
      '대한미용사회중앙회는 인터넷 네트워크를 통해 이용자들에게 다양한 내용의 언론정보 관련 컨텐츠를 서비스 하며 이용자는 인터넷에 접속가능 한 장비를 통해 대한미용사회중앙회에서 제공하는 서비스를 이용할 수 있습니다.',
      '대한미용사회중앙회의 이용자는 대한미용사회중앙회에서 서비스하는 모든 내용에 대해 자유롭게 이용하고 활용할 수 있습니다.',
      '대한미용사회중앙회의 모든 서비스는 일정한 주기 없이 수시로 내용의 업데이트 또는 확장·변경됩니다.',
      '대한미용사회중앙회의 이용자는 대한미용사회중앙회가 제공하는 서비스의 내용에 대한 문제가 있다고 생각되거나 다른 의견에 대해서는 대한미용사회중앙회내의 기사의견, 대화, 게시판, 담당자에 보내는 e-mail을 통해 자신의 의견을 게시할 수 있습니다.',
      '대한미용사회중앙회의 이용자가 자유롭게 대한미용사회중앙회내의 기사의견, 대화, 게시판, 담당자 등에 올린 내용에 대해서는 대한미용사회중앙회는 어떠한 경우도 그 내용에 대한 책임이 없습니다.',
    ],
  },

  {
    title: '대한미용사회중앙회의 이용자가 지켜야 할 사항',
    desc: '대한미용사회중앙회는 아래와 같은 행위에 해당하는 내용을 삭제 또는 제한 할 수 있으며 해당 행위를 한 이용자에 대해 대한미용사회중앙회의 서비스 이용을 제한 할 수 있습니다.',
    terms: [
      '이용자가 대한미용사회중앙회의 회원가입시 등록한 내용에 허위, 기재누락, 오기가 있는 경우',
      '이용자가 대한미용사회중앙회내의 기사의견, 대화, 게시판, 담당자등에 대한미용사회중앙회의 사전 허가 없이 상업적 목적의 광고성 메시지, 타인의 저작권 또는 프라이버시를 침해할 수 있는 내용을 게시할 때',
      '이용자가 대한미용사회중앙회내의 기사의견, 대화, 게시판, 담당자등에 음란물이나 위협적인 내용, 저속한 내용 등의 부적합한 내용을 게시하여 다른 이용자들에게 피해를 줄 수 있는 우려가 있을 때',
      '이용자가 대한미용사회중앙회내의 기사의견, 대화, 게시판, 담당자등에 내용을 타인의 상표, 특허, 영업 비밀, 저작권 등을 침해할 소지가 있는 내용을 게시 할 때',
      '이용자가 다른 이용자의 개인정보를 수집 또는 저장, 배포 등의 부정 행위를 할 때',
    ],
  },

  {
    title: '대한미용사회중앙회 회원의 ID 및 비밀번호에 대한 의무',
    desc: '회원 서비스를 받기 위해 대한미용사회중앙회의 회원으로 가입하신 이용자께서는 회원의 ID와 비밀번호에 대해 다음의 의무를 이행할 책임이 있습니다.',
    terms: [
      'ID와 비밀번호에 관한 관리책임은 대한미용사회중앙회 회원에게 있습니다.',
      '대한미용사회중앙회 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.',
      '대한미용사회중앙회 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 사이트에 통보하고 사이트의 안내가 있는 경우에는 그에 따라야 합니다.',
    ],
  },
  {
    title: '대한미용사회중앙회의 개인정보 보호정책',
    desc: '이용자가 대한미용사회중앙회의 서비스를 받기 위해 제공한 개인정보와 서비스 과정 중 얻게 된 이용자의 개인정보를 보호하기 위해서 최선을 하겠습니다.',
    terms: [
      'ID와 비밀번호에 관한 관리책임은 대한미용사회중앙회 회원에게 있습니다.',
      '대한미용사회중앙회 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.',
      '대한미용사회중앙회 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 사이트에 통보하고 사이트의 안내가 있는 경우에는 그에 따라야 합니다.',
    ],
  },

  {
    title: '대한미용사회중앙회에서 서비스 한 내용에 대한 저작권',
    desc: '대한미용사회중앙회에서 서비스 하는 모든 내용이라 함은 모든 문자, 그래픽, 모든 종류의 파일, 소프트웨어를 말합니다.',
    terms: [
      '대한미용사회중앙회에서 서비스 하는 모든 내용에 대한 저작권은 대한미용사회중앙회 및 대한미용사회중앙회에 컨텐츠를 제공한 제공처, 광고주에 있습니다. 이 모든 내용들은 지적재산권에 의해 보호 받으며 사전 대한미용사회중앙회나 컨텐츠를 제공한 제공처, 광고주의 사전 승인 없이 어떠한 경우도 대여, 배포, 판매, 대출 행위가 금지되어 있습니다.',
    ],
  },
  {
    title: '대한미용사회중앙회에서 서비스 한 컨텐츠의 배포 및 인용',
    desc: '대한미용사회중앙회에서 서비스 하는 모든 컨텐츠에 대한 배포는 금지되어 있습니다.',
    terms: [
      '대한미용사회중앙회에서 서비스한 컨텐츠의 내용을 인용 또는 발췌, 복제, 개조, 수정 하여 상업적인 목적으로 다수를 대상으로 한 배포하는 행위는 금지 되어 있습니다. 다만, 사전 대한미용사회중앙회가 허가한 경우 그 내용의 인용이 가능 합니다.',
    ],
  },
  {
    title: '링크',
    desc: '대한미용사회중앙회 사전 동의 없이 일부 내용을 발췌 또는 인용한 후 대한미용사회중앙회의 특정 페이지로 링크하는 행위를 금지합니다.',
  },
  {
    title: '제공받은 컨텐츠에 대한 책임',
    terms: [
      '대한미용사회중앙회의 컨텐츠 중 대한미용사회중앙회의 자체 컨텐츠가 아니고 별도로 제공처가 표기된 컨텐츠에 대해서는 모든 법적 책임이 내용을 제공한 제공처에 있으며 대한미용사회중앙회는 해당 컨텐츠에 대한 법적 책임이 없습니다.',
      '본 이용 약관의 위반은 대한미용사회중앙회 고객지원(kocoa45@beautym.co.kr)으로 알려주시기 바랍니다.',
    ],
  },
  {
    title: '기타',
    desc: '본 약관은 공정거래위원회 표준 약관에 근거하여 작성되었습니다.',
  },
];

export const privacyTerms1 = [
  {
    title: '개인정보의 수집 및 이용목적',
    desc: '회사는 아래와 같은 목적으로 개인정보를 수집합니다.',
    terms: ['대한미용사회중앙회 소식 안내 및 회원관리'],
  },
  {
    title: '개인정보 수집에 대한 동의',
    desc: "회사는 회원님의 개인정보 수집에 대하여 동의를 받고 있으며, 회사 내의 회원가입 절차 중 이용약관 및 개인정보취급방침에 개인정보 수집 동의절차를 마련해 두고 있습니다. 회원님께서['약관 및 정책'에 동의합니다.]란에 체크하시면 개인정보 수집에 대해 동의한 것으로 봅니다.",
  },
  {
    title: '개인정보 제공 및 공유',
    desc: '회사는 이용자들의 개인정보를 ‘2. 개인정보의 수집 및 이용목적’에서 고지한 범위내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.',
  },
  {
    title: '개인정보 취급위탁 및 제3자 제공',
    desc: '개인정보 취급 위탁	회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.',
    terms: [
      '수탁업체명 : 엔디소프트 (주)',
      '위탁업무 내용 : 홈페이지 유지보수(전산관리 위탁)',
      '개인정보 보유 및 이용기간 : 회원탈퇴 시 혹은 위탁 계약 종료 시까지',
      '개인정보 제3자 제공	회사는 고객님의 동의없이 고객님의 정보를 제3자에게 제공하지 않습니다. 향후 그러한 필요가 생길 경우, 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.',
    ],
  },
  {
    title: '이용자 및 법정대리인의 권리와 그 행사방법',
    terms: [
      '이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 도 있습니다.',
      "이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '회원정보수정'(또는 '회원정보변경' 등)을 가입해지를 위해서는 '회원탈퇴' 를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.",
      '이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.',
      '회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "개인정보의 보유 및 이용기간" 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. 이용자는 정보주체로서 관련 법령에 의거, 개인정보의 열람, 정정·삭제, 처리정지 요구를 할 수 있습니다.',
      '회사는 이에 대해서 10일 이내에 해당 사항에 대한 회사의 조치를 통지하며 개인정보의 열람, 정정·삭제, 처리정지 요구는 회사로 접수하시면 됩니다.',
    ],
  },

  {
    title: '개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항',
    terms: [
      '쿠키(cookie)는 HTTP 서버에서 사용자 브라우저에게 보내는 조그마한 데이터 파일로써 회원님의 컴퓨터에 저장됩니다. 회사는 이러한 쿠키(cookie)를 이용하여 회원님의 브라우저 형식이나 서비스 이용 형태를 얻게 되며, 이러한 쿠키 정보를 바탕으로 회원님께 유용하고 보다 편리한 맞춤서비스를 제공하는데 사용하게 됩니다.',
      '회원님은 쿠키에 대한 선택권을 가지고 있습니다. 회원님의 웹브라우저에서 옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부하실 경우 로그인 (Log-in) 이 필요한 회사의 모든 서비스는 이용하실 수 없게 됩니다.',
    ],
  },

  {
    title: '개인정보보호를 위한 기술적, 관리적 대책',
    desc: '회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원님 계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원님의 비밀번호는 누구에게도 알려주면 안됩니다. 또한 작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를 종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게 알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요합니다. 이용자 부주의나 인터넷상의 문제로 인한 개인정보 유출에 대해서 회사는 책임을 지지 않습니다. 회사는 개인정보보호를 위한 기술적 관리적 대책을 다음과 같이 시행하고 있습니다.',
    terms: [
      '해킹 방지를 위한 방화벽과 보안시스템에 만전을 기하고 있습니다.',
      '개인정보 송수신시 SSL 보안서버 인증서를 설치하여 정보를 보호합니다.',
      '개인정보에의 접근은 해당 업무 수행자, 업무 수행 시 개인정보 취급이 불가피한 자로 제한하여 그 이외의 직원이 접근하지 못하도록 하고 있습니다.',
      '개인정보취급자의 경우 정기 및 수시로 교육을 실시하고 있습니다.',
      '개인정보처리시스템의 접속 기록을 유지하고 이를 정기적으로 확인 감독하고 있습니다.',
      '개인정보 출력 및 복사 시 보호조치를 시행하고 있습니다.',
    ],
  },
  {
    title: '아동의 개인정보',
    terms: [
      '회사는 원칙적으로 14세 미만 아동의 개인정보를 수집하지 않습니다. 다만 특별한 경우에 법정대리인의 동의를 얻어 아동의 개인정보를 수집할 수 있습니다.',
      '회사는 회원님의 개인정보를 보호하기 위하여 최선을 다하고 있습니다. 그러나 이러한 노력 이외에 회원님 스스로도 제 3 자에게 비밀번호 등이 노출되지 않도록 주의하셔야 합니다. 특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상 유의하시기 바랍니다.',
      '또한 회사는 어떠한 경우에도 전화나 E-mail을 통해 사용자의 비밀번호를 묻지 않습니다. 그러므로 본인의 ID와 비밀번호는 꼭 본인만 사용하시고, 비밀번호를 자주 바꿔주시는 것이 좋습니다. 회사는 개인정보보호에 최선을 다하지만, 회원님 개인 실수나 회사의 과실에 기인하지 않는 개인 정보 유출에 대해서는 책임을 지지 않습니다.',
    ],
  },
  {
    title: '개인정보 관리책임자',
    desc: '회사 개인정보취급방침과 관련하여 의견이 있을 경우 메일을 주시면 접수 즉시 조치하고 처리결과를 알려드리겠습니다. 개인정보 관리책임자는 아래와 같습니다.',
    terms: [
      '개인정보 관리책임자: 이선심',
      '전화번호: 02-585-3351~3',
      'E-mail: kocoa45@beautym.co.kr',
      '기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.',
      '개인정보침해신고센터	privacy.kisa.or.kr / 국번없이 118',
      '대검찰청 사이버범죄수사단	www.spo.go.kr / 국번없이 1301',
      '경찰청 사이버테러대응센터	www.ctrc.go.kr / 국번없이 182',
    ],
  },
  {
    title: '부칙',
    desc: '현 개인정보취급방침의 수정, 변경이 있을 시 최소 7일 이전에 사이트 내 공지를 통하여 고지할 것입니다.',
  },
];
