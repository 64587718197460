<template>
  <v-row class="h-100" :class="{ 'gnb-border-bottom': !isHome }" justify="space-between" align="center">
    <v-col class="gnb-row__left" style="height: 80%">
      <slot name="left" />
    </v-col>

    <v-col class="gnb-row__mobile gnb-row__mobile__title">
      <slot name="mobile-center" />
    </v-col>

    <v-col class="gnb-row__pc gnb-row__center p-0" cols="8">
      <slot name="center" />
    </v-col>

    <v-col class="gnb-row__mobile gnb-row__right gnb-row__right-md-visible">
      <slot name="mobile-right" />
    </v-col>

    <v-col class="gnb-row__pc gnb-row__right">
      <slot name="right" />
    </v-col>
  </v-row>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const isHome = computed(() => route.name === 'home');

    return { isHome };
  },
};
</script>

<style lang="scss" scoped>
// fixme: subMenu...펼쳤을 때 하단에도 같이 적용되어 임시로 주석처리
//.gnb-border-bottom {
//  border-bottom: 1px solid $gray-20;
//}

.gnb-row {
  &__mobile {
    @include md-and-up {
      display: none !important;
    }

    &__title {
      @include spreadTypoMap($title-small);
      color: $gray-80;
      text-align: center;
    }
  }

  &__pc {
    height: 100%;
    @include md-and-down {
      display: none !important;
    }
  }

  &__left {
    padding-left: 3.25rem;

    @include md-and-down {
      padding-left: 1rem;
    }
  }

  &__center {
    width: 70rem;
  }

  &__right {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 3.25rem;

    @include md-and-down {
      padding-right: 1rem;
    }

    &-md-visible {
      @include md {
        display: flex !important;
      }
    }
  }
}
</style>
