import axiosInstance from '@/lib/axiosController';
// import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash-es';

export default defineStore({
  id: 'menu',
  state: () => ({
    menu: [],
    menuMap: {},
    writeAuthorityMap: {},
  }),
  getters: {
    getMenu: (state) => state.menu,
    getMenuMap: (state) => state.menuMap,
    getWriteAuthorityMap: (state) => state.writeAuthorityMap,
  },
  actions: {
    async updateMenuList() {
      const userMenuList = await axiosInstance.get('/user-menu');
      this.menu = userMenuList;
      const copiedMenuList = cloneDeep(userMenuList);
      this.menuMap = copiedMenuList.reduce((map, obj) => {
        if (obj?.childMenus?.length !== 0) {
          map[obj.id] = obj.childMenus;
        }
        return map;
      }, {});
      this.writeAuthorityMap = Object.values(this.menuMap).reduce((map, obj) => {
        obj.forEach((e) => {
          // console.log(e);
          if (e?.id) {
            map[e.path] = e;
          }
        });
        return map;
      }, {});
    },
  },
});
