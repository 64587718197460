<template>
  <popover-row v-for="(attr, idx) in attributes" :key="attr.key" :attribute="attr">
    <ul class="box" :style="idx === 0 ? 'border: none' : ''">
      <li>{{ attr.customData.title }}</li>
      <li>( {{ getMMDD(attr.targetDate.start) }} ~ {{ getMMDD(attr.targetDate.end) }} )</li>
      <li v-if="attr.customData.description" class="description">
        {{ attr.customData.description }}
      </li>
    </ul>
  </popover-row>
</template>

<script>
import { PopoverRow } from 'v-calendar';
import { getKrDate } from '@/utils/date';

export default {
  name: 'MainCalendarPopover',
  components: { PopoverRow },
  props: {
    day: {
      type: Object,
      required: true,
    },
    format: {
      type: Function,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { getMMDD: (date) => getKrDate(date).format('MM/DD') };
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  max-width: 300px;
  align-self: center;
  list-style: none;
  margin-bottom: 4px;
  padding: 4px;

  border-top: 1px solid #999;

  li + li {
    margin-top: 2px;
  }

  li {
    word-break: break-word;
  }
}

.description {
  width: 100%;
  background: #4b576b;
  padding: 2px;
  border-radius: 2px;
}
</style>
