<template>
  <section class="introduction-row">
    <h1 class="introduction-row__title item">
      아름다운<br />
      프로페셔널
    </h1>
    <p class="introduction-row__description item">
      대한미용사회중앙회는 미용을 업으로 하는 미용인들이 미용업 발전 및 기술향상과 회원 상호간의 <br />
      친목을 도모하여 공중위생과 국민보건의 향상에 기여함을 목적으로 결성된 단체입니다.
    </p>
    <v-btn class="introduction-row__btn item" color="white" size="x-large" to="/about"
      >더 알아보기 <icon-base><chevron-right-arrow /></icon-base
    ></v-btn>
  </section>
</template>

<script>
import ChevronRightArrow from '@/icons/ChevronRightArrow';

export default {
  components: { ChevronRightArrow },
  setup() {
    return { ChevronRightArrow };
  },
};
</script>

<style scoped lang="scss">
.introduction-row {
  margin-top: 56px;
  padding: 0 20px;

  @include md-and-up {
    margin-top: 96px;
    padding: 0;
  }

  & > .item + .item {
    margin-top: 16px;

    @include md-and-up {
      margin-top: 24px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    color: white;
    margin-bottom: 0;

    br {
      display: block;
    }

    @include md-and-up {
      @include spreadTypoMap($title-xxLarge);
      color: white;

      br {
        display: none;
      }
    }
  }

  &__description {
    @include spreadTypoMap($body-xsmall);
    color: white;
    word-break: keep-all;
    margin-bottom: 0;

    br {
      display: none;
    }

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: white;

      br {
        display: block;
      }
    }
  }

  &__btn {
    @include spreadTypoMap($button-small);
    height: 100% !important;
    padding: 12px 10px 14px 18px;

    svg {
      display: block;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }

    @include md-and-up {
      @include spreadTypoMap($button-large);
      padding: 16px 16px 16px 24px;

      svg {
        width: 24px;
        height: 24px;
        margin-left: 8px;
      }
    }
  }
}
</style>
