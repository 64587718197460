<template>
  <section class="popular-article__wrapper deep__wrapper">
    <h2 class="popular-article__main-title">인기 기사</h2>
    <tabs-vue v-model="selectedTab" is-customized-tab-action :tabs="mockTabsData" :tab-action="onClickTab" is-full-width />
    <v-window v-model="selectedTab">
      <v-window-item v-for="tab in mockTabsData" :key="tab.id" :value="tab.id">
        <empty-data-card v-if="postInfos.length === 0" />
        <div v-if="postInfos.length !== 0">
          <template v-for="(news, idx) in postInfos" :key="news.subtitle">
            <subtitle-text-row-vue :title="news.title" :subtitle="`${idx + 1}위`" :news-id="news.id" class="news-row" />
          </template>
        </div>
      </v-window-item>
    </v-window>
  </section>
</template>

<script>
import { ref } from 'vue';
import { getPopularNewsAPI } from '@/apis/__main__.js';
import { ARTICLE_TYPE } from '@/constants/article';
import SubtitleTextRowVue from '@/components/SubtitleTextRow.vue';
import TabsVue from '@/components/Tab2.vue';
import { errorToast } from '@/utils/toast';
import { UNEXPECTED_ERROR } from '@/constants/commons/errors';
import EmptyDataCard from '@/components/EmptyDataCard.vue';

export default {
  components: {
    TabsVue,
    SubtitleTextRowVue,
    EmptyDataCard,
  },
  setup() {
    const selectedTab = ref(null);
    const postInfos = ref([]);

    const onClickTab = async (id) => {
      try {
        const news = await getPopularNewsAPI(id, 7);
        postInfos.value = news;
      } catch (e) {
        errorToast(e.message || UNEXPECTED_ERROR);
      }
    };

    const mockTabsData = [
      { id: ARTICLE_TYPE.NEWS.ID, name: ARTICLE_TYPE.NEWS.NAME },
      { id: ARTICLE_TYPE.BEAUTY.ID, name: ARTICLE_TYPE.BEAUTY.NAME },
      { id: ARTICLE_TYPE.COLUMN.ID, name: ARTICLE_TYPE.COLUMN.NAME },
    ];

    const init = () => {
      onClickTab(ARTICLE_TYPE.NEWS.ID);
    };

    init();

    return { mockTabsData, selectedTab, onClickTab, postInfos };
  },
};
</script>

<style lang="scss" scoped>
.popular-article {
  &__main-title {
    @include spreadTypoMap($title-small);

    @include md-and-up {
      @include spreadTypoMap($title-large);
    }
  }

  &__list {
    & > :not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.news-row {
  display: block;
  margin-top: 32px;

  @include md-and-up {
    &:first-of-type {
      margin-top: 24px;
    }
  }
}

.deep__wrapper:deep {
  .custom-tab-group {
    margin-top: 16px;
  }

  .tab:first-of-type {
    margin-inline-start: 0 !important;
  }

  .custom-tab {
    @include spreadTypoMap($caption-medium);
    color: $gray-50;

    @include md-and-up {
      @include spreadTypoMap($button-xLarge);
      color: $gray-50;
    }
  }
}
</style>
