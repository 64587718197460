<template>
  <v-app>
    <GNB-layout>
      <router-view />
    </GNB-layout>
  </v-app>
</template>

<script>
import GNBLayout from '@/layout/GNBLayout.vue';

import '@/styles/global.css';
import { getToken } from '@/utils/tokenUtil';
import { useMenuStore, useUserStore } from '@/stores';
import { setAuthHeader } from '@/lib/axiosController';

export default {
  name: 'App',
  components: {
    GNBLayout,
  },
  async beforeCreate() {
    const token = getToken();
    const userStore = useUserStore();
    const menuStore = useMenuStore();

    if (token) {
      setAuthHeader(token);
      await userStore.refreshLogin(token);
    }
    await menuStore.updateMenuList();
  },
};
</script>

<style lang="scss">
.v-application {
  [class*='text-'] {
    font-family: $font-family !important;
  }
  font-family: $font-family !important;
}
</style>
