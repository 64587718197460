<template>
  <v-navigation-drawer v-model="modelValue" location="right" width="300" absolute temporary>
    <v-list open-strategy="single" nav width="100%">
      <v-list-item>
        <div class="d-flex">
          <div v-if="!userStore.getUserView?.id" style="flex: 1">
            <a class="link mr-3" href="/signin">로그인</a>
            <a class="link" href="/signup">회원가입</a>
          </div>
          <div v-else style="flex: 1">
            <a class="link mr-3" href="#" @click="handleLogout">로그아웃</a>
          </div>
          <div>
            <v-btn class="justify-end" size="xx-small" flat @click="$emit('update:modelValue', false)">
              <v-img alt="menu" src="@/assets/menu-close.svg" width="20px" />
            </v-btn>
          </div>
        </div>
        <div class="d-flex"></div>
      </v-list-item>

      <v-list-group v-for="item in menuStore.getMenu" :key="item.id" active-color="#0070bc" :value="item.id" :collapse-icon="mdiChevronUp">
        <template #activator="{ props }">
          <v-list-item class="group__text" :value="item.id" :title="item.name" v-bind="props"></v-list-item>
        </template>

        <v-list-item v-for="subItem in menuStore.getMenuMap[item.id]" :key="subItem.id" :subtitle="subItem.name" :href="subItem.path"></v-list-item>
        <v-divider color="#d3d6db"></v-divider>
      </v-list-group>
    </v-list>
    <div v-if="userStore.getUserView?.id" style="display: flex; flex-direction: column; align-items: center; width: 100%">
      <a style="text-decoration: underline" class="link mr-3" href="#" @click="confirmDialog">회원탈퇴 진행하기</a>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mdiChevronUp } from '@mdi/js';
import { useMenuStore, useUserStore } from '@/stores';
import { useRouter } from 'vue-router';
import { withdrawUser } from '@/apis/user';
import { successToast } from '@/utils/toast';

export default {
  setup() {
    const userStore = useUserStore();
    const menuStore = useMenuStore();
    const router = useRouter();
    const handleLogout = () => {
      userStore.logout();
      router.push('/');
    };

    const confirmDialog = () => {
      if (window.confirm('회원 탈퇴를 진행하시겠습니까?')) {
        deleteUser();
      }
    };

    const deleteUser = async () => {
      try {
        await withdrawUser();
        successToast('회원 탈퇴가 완료되었습니다.');
        setTimeout(() => {
          handleLogout();
        }, 1000);
      } catch (e) {
        successToast('회원 탈퇴에 실패하였습니다.');
      }
    };

    return { mdiChevronUp, menuStore, userStore, handleLogout, deleteUser, confirmDialog };
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-list-item-title) {
  font-size: 16px !important;
}

:deep(.v-list-item-subtitle) {
  font-size: 14px !important;
}

.link {
  @include spreadTypoMap($body-xsmall);
  color: $gray-50;
  text-decoration: none;
}
</style>
