<template>
  <page title="게시글 작성">
    <template #main-section>
      <post-edit-view-loading v-if="postDetailLoading" :breadcrumbs="breadcrumbs" />
      <post-edit-view
        v-else
        :breadcrumbs="breadcrumbs"
        :sub-category="(menuStore.getMenuMap[16] || []).filter((e) => e.writeAuthority)"
        :post-detail-data="postDetailData"
      ></post-edit-view>
      <secure-post-password-dialog
        :secure-post-dialog="securePostDialog"
        :get-secure-post="getSecurePost"
        :post-password="postPassword"
        :go-back="goBackToPost"
        go-back-text="게시글로 돌아가기"
        go-text="수정하러 가기"
        @update:postPassword="postPassword = $event"
      />
    </template>
  </page>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PostEditView from '@/containers/Post/PostEditView.vue';
import Page from '@/components/Page.vue';

import { useMenuStore } from '@/stores';
import { getPostSpecificAPI } from '@/apis/__post__';
import PostEditViewLoading from '@/containers/Post/PostEditViewLoading';
import SecurePostPasswordDialog from '@/containers/Post/SecurePostPasswordDialog.vue';

/**
 * new post : /write?mainCategory=aaa
 * edit post : /write?postId=123
 */
export default {
  components: { PostEditViewLoading, PostEditView, Page, SecurePostPasswordDialog },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const query = computed(() => route.query);

    const postId = query.value.postId;
    const isSecurePost = route.query.type === 'secure';

    const menuStore = useMenuStore();

    const breadcrumbs = [
      { text: '홈', href: '/', disabled: false },
      { text: '자료&게시판', href: '#', disabled: true },
      { text: '게시글 작성', href: '#', disabled: true },
    ];

    const securePostDialog = ref(isSecurePost);
    const postPassword = ref('');

    const postDetailLoading = ref(!!postId);
    const postDetailData = ref({
      boardId: '',
      body: '',
      postFileViewList: [],
      createdDate: '',
      id: '',
      thumbnail: '',
      title: '',
      type: '',
      userId: 0,
    });

    const getSecurePost = async () => {
      try {
        const postData = await getPostSpecificAPI(postId, { password: postPassword.value });
        postDetailData.value = postData;
        postDetailLoading.value = false;
        securePostDialog.value = false;
      } catch (e) {
        // console.log(e);
      }
    };

    const goBackToPost = async () => {
      router.push(`/post/${postId}?type=secure`);
    };

    const getPostSpecific = async () => {
      try {
        const postData = await getPostSpecificAPI(postId);
        postDetailData.value = postData;
        postDetailLoading.value = false;
      } catch (e) {
        // 비밀글 게시글에 URL로 바로 접속한 경우
        if (e.code === -4004) {
          securePostDialog.value = true;
        }
      }
    };

    if (postId && !isSecurePost) {
      getPostSpecific();
    }

    return {
      postId: query.postId,
      securePostDialog,
      goBackToPost,
      getSecurePost,
      postPassword,
      breadcrumbs,
      menuStore,
      postDetailLoading,
      postDetailData,
    };
  },
};
</script>

<style></style>
