<template>
  <v-fade-transition hide-on-leave>
    <div v-if="isHover" class="gnb-menu">
      <GNB-row>
        <template #center>
          <div class="gnb-menu__wrapper">
            <template v-for="subNav in Object.values(menuStore.getMenuMap)" :key="subNav.id">
              <div class="gnb-menu__column">
                <v-btn v-for="item in subNav" :key="item.id" class="gnb-menu__btn" :href="item.path" width="100%" size="x-large">{{ item.name }}</v-btn>
              </div>
            </template>
          </div>
        </template>
      </GNB-row>
    </div>
  </v-fade-transition>
</template>

<script>
import GNBRow from '@/components/GNBRow.vue';
// import { MAIN_SUB_NAV_MAP } from '@/constants/navigation';
import { useMenuStore } from '@/stores';

export default {
  name: 'GNBMenu',
  components: { GNBRow },
  props: {
    isHover: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const menuStore = useMenuStore();
    return {
      menuStore,
    };
  },
};
</script>

<style lang="scss" scoped>
.gnb-menu {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  position: absolute;
  top: 89px;
  right: 0;
  left: 0;
  box-shadow: 0px 4px 12px 2px rgba(24, 30, 45, 0.15);
  background-color: white;

  &__wrapper {
    display: flex;
    justify-content: space-around;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 10 * 4rem);
  }

  &__btn {
    @include spreadTypoMap($caption-medium);
    color: $gray-50;
    padding: 0;

    &:hover {
      color: $blue-50;
    }

    @include lg {
      @include spreadTypoMap($caption-small);
      color: $gray-50;
    }
  }
}
</style>
