<template>
  <page>
    <template #main-section>
      <post-detail-view
        ref="postDetailRef"
        :post-data="postDetailData"
        :comments="commentData"
        :breadcrumbs="breadcrumbs"
        :post-id="postId"
        :post-loading="!postDetailData"
      ></post-detail-view>
      <secure-post-password-dialog
        :secure-post-dialog="securePostDialog"
        :get-secure-post="getSecurePost"
        :go-back="goBack"
        :post-password="postPassword"
        go-back-text="목록으로 돌아가기"
        @update:postPassword="postPassword = $event"
      />
    </template>
  </page>
</template>

<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PostDetailView from '@/containers/Post/PostDetailView';

import Page from '@/components/Page.vue';

import { getPostSpecificAPI } from '@/apis/__post__';
import SecurePostPasswordDialog from '@/containers/Post/SecurePostPasswordDialog.vue';

/**
 * new post : /write?mainCategory=aaa
 * edit post : /write?postId=123
 */
export default {
  components: { PostDetailView, Page, SecurePostPasswordDialog },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const postDetailData = ref();
    const commentData = ref([]);
    const postDetailRef = ref();

    const isSecurePost = route.query.type === 'secure';
    const postPassword = ref('');
    const securePostDialog = ref(isSecurePost);

    const postId = route.params.id;

    const getSecurePost = () => {
      Promise.all([getPostSpecificAPI(postId, { password: postPassword.value })]).then((resArr) => {
        postDetailData.value = resArr[0];
        postDetailRef.value.updateBody(resArr[0].body);
        securePostDialog.value = false;
      });
    };

    if (postId && !isSecurePost) {
      Promise.all([getPostSpecificAPI(postId)])
        .then((resArr) => {
          postDetailData.value = resArr[0];
          postDetailRef.value.updateBody(resArr[0].body);
          securePostDialog.value = false;
        })
        .catch((e) => {
          // 비밀글 게시글에 URL로 바로 접속한 경우
          if (e.code === -4004) {
            securePostDialog.value = true;
          }
        });
    }

    const goBack = () => {
      router.push('/community/14');
    };

    document.title = '게시물 상세';

    // const currentMainCategory = mainCategory[query.value.mainCategory] ?? mainCategory.community;

    const breadcrumbs = [
      { text: '홈', href: '/', disabled: false },
      // { text: currentMainCategory.label, href: currentMainCategory.href, disabled: false },
    ];

    return {
      postId,
      goBack,
      // subCategory: currentMainCategory.subCategory,
      postPassword,
      getSecurePost,
      securePostDialog,
      breadcrumbs,
      postDetailData,
      commentData,
      postDetailRef,
    };
  },
};
</script>

<style></style>
