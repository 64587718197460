<template>
  <router-link :to="to" class="category-card" :class="{ 'category-card-translucent': translucent }" @click="onClick">
    <div class="category-card__content">
      <h4>{{ title }}</h4>
      <h6>{{ desc }}</h6>
    </div>

    <icon-base icon-color="white"><right-arrow /></icon-base>
  </router-link>
</template>

<script>
import RightArrow from '@/icons/RightArrow';

export default {
  components: {
    RightArrow,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      required: true,
    },
    translucent: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card {
  width: 100%;
  height: auto;
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  background: rgba(0, 112, 188, 0.5);

  border: 1px solid $blue-50;
  backdrop-filter: blur(20px);
  border-radius: 8px;

  @include lg-and-up {
    height: 324px;
    flex-direction: column;
    padding: 24px;
  }

  @include md {
    height: 300px;
  }

  &__content {
    @include md-and-up {
      align-self: flex-start;
    }
    h4 {
      @include spreadTypoMap($title-xsmall);
      color: white;

      @include md-and-up {
        @include spreadTypoMap($title-medium);
        color: white;
      }
    }

    h6 {
      margin-top: 8px;
      @include spreadTypoMap($body-xsmall);
      color: white;
      word-break: keep-all;
    }
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;

    @include md-and-up {
      align-self: flex-end;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &-translucent {
    background: rgba(24, 30, 45, 0.5);
    backdrop-filter: blur(20px);
    border-color: $gray-70;

    @include md-and-up {
      border-color: $black;
    }
  }
}
</style>
