<template>
  <section class="major-news__wrapper deep__wrapper">
    <h2 class="major-news__title text-center">중요 뉴스</h2>
    <tab2 v-model="selectedTab" is-customized-tab-action :tabs="mockTabsData" :tab-action="onClickTab" is-full-width class="item" />
    <div class="major-news__card-wrapper item">
      <empty-data-card v-if="postInfos.length === 0" />
      <template v-for="post in postInfos" :key="post.id">
        <image-summary-card-vue :img-src="post.thumbnail" :title="post.title" :desc="post.bodySummary" :news-id="post.id" />
      </template>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';

import Tab2 from '@/components/Tab2.vue';
import ImageSummaryCardVue from '@/components/ImageSummaryCard.vue';
import { getImportantArticleAPI } from '@/apis/__main__';
import { ARTICLE_TYPE } from '@/constants/article';
import { UNEXPECTED_ERROR } from '@/constants/commons/errors';
import { errorToast } from '@/utils/toast';

export default {
  components: { Tab2, ImageSummaryCardVue },
  setup() {
    const selectedTab = ref(null);
    const postInfos = ref([]);

    const onClickTab = async (id) => {
      try {
        const news = await getImportantArticleAPI(id);
        postInfos.value = news;
      } catch (e) {
        errorToast(e.message || UNEXPECTED_ERROR);
      }
    };
    const mockTabsData = [
      { id: 1, name: ARTICLE_TYPE.CENTRAL.NAME },
      { id: 2, name: ARTICLE_TYPE.COMMITTEE.NAME },
      { id: 3, name: ARTICLE_TYPE.BRANCH.NAME },
      { id: 4, name: ARTICLE_TYPE.COMPANY.NAME },
      { id: 5, name: ARTICLE_TYPE.ISSUE.NAME },
      { id: 6, name: ARTICLE_TYPE.EVENT.NAME },
    ];

    const init = () => {
      onClickTab(1);
    };

    init();

    return { mockTabsData, selectedTab, onClickTab, postInfos };
  },
};
</script>

<style lang="scss" scoped>
.major-news {
  &__wrapper {
    padding: 48px 20px;
    background: $gray-80;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include md-and-up {
      padding: 72px 80px;
      margin-top: 96px;
      border-radius: 8px;
      box-shadow: 0 4px 12px 2px rgba(24, 30, 45, 0.15);
    }
  }

  &__title {
    @include spreadTypoMap($title-small);
    color: $gray-20;
    margin-bottom: 0;

    @include md-and-up {
      @include spreadTypoMap($title-large);
      color: $gray-20;
    }
  }

  &__card-wrapper {
    padding: 16px 0;
    display: grid;
    grid-gap: 16px;

    @include md {
      padding: 0;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(2, minmax(160px, auto));
      width: 100%;
    }

    @include lg-and-up {
      padding: 0;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(4, minmax(160px, auto));
      width: 100%;
    }
  }
}

.deep__wrapper:deep {
  .custom-tab-group {
    margin-top: 8px;
    background: transparent !important;
    width: 100%;

    @include md-and-up {
      width: fit-content;
    }
  }

  .tab:first-of-type {
    margin-inline-start: 0 !important;
  }

  .custom-tab {
    @include spreadTypoMap($caption-medium);
    color: $gray-50;

    @include md-and-up {
      @include spreadTypoMap($button-xLarge);
      color: $gray-50;

      .v-tab__slider {
        color: $gray-20 !important;
        caret-color: $gray-20 !important;
      }
    }
  }

  .custom-tab-group button[aria-selected='true'] {
    font-weight: 700;
    color: $gray-20;
  }
}

.item {
  margin-top: 8px;

  @include md-and-up {
    margin-top: 24px;
  }
}
</style>
