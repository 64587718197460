import { createRouter, createWebHistory } from 'vue-router';
import { ADMIN_PERMISSION } from '@/constants/role';
import AboutView from '@/views/AboutView.vue';
import SignIn from '@/views/SignIn.vue';
import SignUp from '@/views/SignUp.vue';
import Community from '@/views/Community';
import News from '@/views/News';
import PostEdit from '@/views/PostEdit';
import PostDetail from '@/views/PostDetail';
import UsedMarketPostEdit from '@/views/UsedMarketPostEdit';
import UsedMarketPostDetail from '@/views/UsedMarketPostDetail';

import HomeView from '@/views/HomeView.vue';
import Committee from '@/views/Committee';
import Beauty from '@/views/Beauty';
import NewsDetail from '@/views/NewsDetail';
import Culture from '@/views/Culture';
import RegularReport from '@/views/RegularReport';
import ProductMall from '@/views/ProductMall';
import PrivacyInfo from '@/views/PrivacyInfo';
import RegularReportDetail from '@/views/RegularReportDetail';
import Contest from '@/views/Contest';
import ContestPlayerRegist from '@/views/ContestPlayerRegist.vue';
import ContestPlayerUpdate from '@/views/ContestPlayerUpdate.vue';
import { useScrollPositionStore } from '@/stores';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { GNBTransparent: true },
  },
  {
    path: '/about/:aboutId',
    name: 'about',
    component: AboutView,
    meta: {
      title: '대한미용사회중앙회 소개',
    },
  },
  {
    path: '/signin',
    name: 'signInPage',
    component: SignIn,
    meta: {
      title: '로그인',
    },
  },
  {
    path: '/signup',
    name: 'signUpPage',
    component: SignUp,
    meta: {
      title: '회원가입',
    },
  },
  {
    path: '/secret',
    name: 'secretPage',
    component: AboutView,
    meta: { accessible: ADMIN_PERMISSION },
  },
  {
    path: '/community/:communityId',
    name: 'communityPage',
    component: Community,
    meta: {
      title: '자료&커뮤니티',
    },
  },
  {
    path: '/news/:newsId',
    name: 'newsPage',
    component: News,
    meta: {
      title: '뉴스',
    },
  },
  {
    path: '/committee/:committeeId',
    name: 'committeePage',
    component: Committee,
    meta: {
      title: '산하위원회',
    },
  },
  {
    path: '/beauty/:beautyId',
    name: 'beautyPage',
    component: Beauty,
    meta: {
      title: '뷰티',
    },
  },
  {
    path: '/culture/:cultureId',
    name: 'culturePage',
    component: Culture,
    meta: {
      title: '칼럼&문화',
    },
  },
  {
    path: '/write',
    name: 'PostWritePage',
    component: PostEdit,
    meta: {
      title: '게시물 작성',
    },
  },
  {
    path: '/used-market/write',
    name: 'UsedMarketPostWritePage',
    component: UsedMarketPostEdit,
    meta: {
      title: '게시물 작성',
    },
  },
  {
    path: '/post/:id',
    name: 'PostDetailPage',
    component: PostDetail,
    meta: {
      title: '게시물 상세',
    },
  },
  {
    path: '/used-market/post/:id',
    name: 'UsedMarketPostDetailPage',
    component: UsedMarketPostDetail,
    meta: {
      title: '게시물 상세',
    },
  },
  {
    path: '/news-detail/:newsId',
    name: 'NewsDetailPage',
    component: NewsDetail,
    meta: {
      title: '뉴스',
    },
  },
  {
    path: '/regular-report',
    name: 'RegularReportPage',
    component: RegularReport,
    meta: {
      title: '미용회보',
    },
  },
  {
    path: '/regular-report/:reportId',
    name: 'RegularReportDetailPage',
    component: RegularReportDetail,
    meta: {
      title: '미용회보',
    },
  },
  {
    path: '/product-mall',
    name: 'ProductMallPage',
    component: ProductMall,
    meta: {
      title: '금융몰',
    },
  },
  {
    path: '/static/privacy-info',
    name: 'PrivacyInfo',
    component: PrivacyInfo,
    meta: {
      title: '개인정보처리방침',
    },
  },
  {
    path: '/contest/:contestTabId',
    name: 'Contest',
    component: Contest,
    meta: {
      title: '한국 미용 페스티벌',
    },
  },
  {
    path: '/ikbf2022',
    redirect: '/contest/1',
  },
  {
    path: '/contest-form/regist/:contestRegisterType',
    name: 'ContestPlayerRegist',
    component: ContestPlayerRegist,
    meta: {
      title: '대회 선수 접수',
    },
  },
  {
    path: '/contest-form/update',
    name: 'ContestPlayerUpdate',
    component: ContestPlayerUpdate,
    meta: {
      title: '대회 접수 수정',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.name === 'Contest' && to.name === 'Contest') {
      const scrollPositionStore = useScrollPositionStore();
      const { top = 0, left = 0 } = scrollPositionStore?.getScrollPosition || {};

      return { top, left };
    }
    return { top: 0, behavior: 'instant' };
  },
});

export default router;
