<template>
  <router-link :to="`/news-detail/${newsId}`" class="wrapper">
    <div class="rank">
      {{ subtitle }}
    </div>
    <h5 class="title text-truncate">
      {{ title }}
    </h5>
  </router-link>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    newsId: {
      type: Number,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  padding: 0;
  text-decoration: none;

  @include md-and-up {
    padding: 0 16px;
  }
}

.rank {
  @include spreadTypoMap($caption-xsmall);
  color: $gray-50;
  margin-bottom: 16px;
}

.title {
  @include spreadTypoMap($title-xxsmall);

  @include md-and-up {
    @include spreadTypoMap($title-xsmall);
  }
}
</style>
