<template>
  <div>
    <!--    <div class="filter-search-wrapper">-->
    <!--      <select-box :options="selectedOptions" :icon-width="18" :icon-height="18" />-->
    <!--      <button class="search-btn">-->
    <!--        글 검색<icon-base icon-name="search" width="18" height="18" style="margin-left: 2px" icon-color="none" icon-stroke="#181E2D"><search /></icon-base>-->
    <!--      </button>-->
    <!--    </div>-->
    <!--  게시글-->
    <ul style="padding-left: 0">
      <empty-data-card v-if="posts.length === 0" />
      <li v-for="post in posts" :key="post.postId" class="list-card" :data-post-id="post.postId" @click="goDetailPost(post.postId)">
        <p class="list-author">{{ post.author }}</p>
        <p class="list-title">
          <v-chip class="chip" :color="MARKET_TYPE[post.type] ? MARKET_TYPE[post.type].COLOR : ''" label size="small">
            {{ MARKET_TYPE[post.type] ? MARKET_TYPE[post.type].NAME : '' }}
          </v-chip>
          {{ post.title }} <span v-if="post.replySize != 0" style="color: red">[{{ post.replySize }}]</span>
        </p>
        <span class="list-footer">{{ toYYYYMMDD(post.createdDate) }}</span>
        <span class="list-footer">조회수 {{ post.viewCount }}</span>
      </li>
    </ul>
    <div v-if="!isFinished" class="more-btn-wrapper">
      <button class="more-btn" @click="onPressNext">더보기</button>
    </div>
    <!--  플로팅 버튼-->
    <button v-if="!hideWriteButton" class="add-btn" @click="goToWritePage">
      <icon-base icon-color="white" icon-name="plus" style="margin-right: 8px"><plus /></icon-base>
      글 작성하기
    </button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import Plus from '@/icons/Plus';
// import Search from '@/icons/Search';
// import SelectBox from '@/components/SelectBox';
import { ref } from 'vue';
import { toYYYYMMDD } from '@/utils/date';
import { MARKET_TYPE } from '@/constants/usedMarket';

import EmptyDataCard from '@/components/EmptyDataCard.vue';

export default {
  name: 'BoardMobile',
  components: { Plus, EmptyDataCard },
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    posts: {
      type: Array,
      default: () => [],
    },
    hideWriteButton: {
      type: Boolean,
      default: () => false,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const router = useRouter();
    const page = ref(1);

    const goDetailPost = (id) => {
      router.push(`/used-market/post/${id}`);
    };

    const goToWritePage = () => {
      router.push('/used-market/write?mainCategory=community');
    };

    const onPressNext = () => {
      page.value += 1;
      props.onPageChange(page.value);
    };

    const initPage = () => {
      page.value = 1;
    };

    return {
      goDetailPost,
      goToWritePage,
      onPressNext,
      initPage,
      toYYYYMMDD,
      MARKET_TYPE,
    };
  },
};
</script>

<style lang="scss" scoped>
// 선택 + 검색
.filter-search-wrapper:deep {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .select-box__selected {
    @include spreadTypoMap($body-xsmall);
    color: $blue-50;
    padding: 7px 0;
  }

  .select-box__options {
    @include spreadTypoMap($body-xsmall);
  }

  .select-box__icon {
    margin-left: 2px;
  }
}

.dropdown-wrapper {
  width: 45px;
}

.search-type {
  @include spreadTypoMap($body-xsmall);
  color: $blue-50;
}

.search-btn {
  @include spreadTypoMap($button-xsmall);

  justify-content: center;
  align-items: center;
  border: 1px solid $gray-30;
  border-radius: 3px;
  padding: 9px 9px 9px 12px;
}

// 게시글 목록
.list-card {
  list-style: none;
  padding: 1rem;
  background: white;
  border-bottom: 1px solid $gray-20;

  &:hover {
    cursor: pointer;
  }
}

.list-author {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.07em;
  color: $black;
  margin-bottom: 8px;
}

.list-title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.07em;
  color: $black;
  margin-bottom: 8px;
}

.list-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.07em;
  color: $gray-30;

  & + & {
    margin-left: 4px;
  }
}

.more-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.more-btn {
  border: 1px solid $gray-20;
  padding: 10px 41.5px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}

// 플로팅 버튼
.add-btn {
  @include spreadTypoMap($button-large);
  color: white;

  position: fixed;
  left: 0;
  bottom: 0;
  z-index: $community-write-button-z-index;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background: $blue-50;
}

.chip {
  @include spreadTypoMap($body-xxsmall);

  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}
</style>
