<template>
  <router-link :to="`/news-detail/${newsId}`" class="image-summary-card">
    <img :src="imgSrc" alt="img" />
    <div style="width: 100%">
      <h5 class="image-summary-card__title">{{ title }}</h5>
      <p class="image-summary-card__desc">{{ desc }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    newsId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
// 로컬 변수
$visible-lines-mobile: 2;
$visible-lines-pc: 3;

.image-summary-card {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;

  @include md-and-up {
    flex-direction: column;
  }

  img {
    max-width: 120px;
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    margin-right: 16px;

    @include md-and-up {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &__title {
    @include spreadTypoMap($title-xxsmall);
    color: $gray-20;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include sm-and-up {
      width: 450px;
    }

    @include md-and-up {
      @include spreadTypoMap($title-small);
      color: $gray-20;
      margin-top: 16px;
      width: 100%;
    }
  }

  &__desc {
    @include spreadTypoMap($body-xsmall);
    color: $gray-50;
    max-height: calc(#{map-get($body-xsmall, 'font-size')} * #{map-get($body-xsmall, 'line-height')} * #{$visible-lines-mobile});
    -webkit-line-clamp: $visible-lines-mobile;
    margin-bottom: 0;

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: $gray-50;
      margin-top: 16px;
      max-height: calc(#{map-get($body-medium, 'font-size')} * #{map-get($body-medium, 'line-height')} * #{$visible-lines-pc});
      -webkit-line-clamp: $visible-lines-pc;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
</style>
