<template>
  <section class="latest-article__wrapper deep__wrapper">
    <h2 class="latest-article__main-title">신규 뉴스</h2>
    <tabs-vue v-model="selectedTab" is-customized-tab-action :tabs="mockTabsData" :tab-action="onClickTab" is-full-width />
    <v-window class="latest-article__list">
      <v-window-item v-for="tab in mockTabsData" :key="tab.id" :value="tab.id">
        <empty-data-card v-if="postInfos.length === 0" />
        <div v-if="postInfos.length !== 0">
          <template v-for="post in postInfos" :key="post.id">
            <image-summary-row-vue :src="post.thumbnail" :title="post.title" :body-summary="post.bodySummary" :news-id="post.id" />
          </template>
        </div>
      </v-window-item>
    </v-window>
  </section>
</template>

<script>
import TabsVue from '@/components/Tab2.vue';
import ImageSummaryRowVue from '@/components/ImageSummaryRow.vue';
import { ref } from 'vue';
import { getLatestArticleAPI } from '@/apis/__main__';
import { ARTICLE_TYPE } from '@/constants/article';

export default {
  components: { TabsVue, ImageSummaryRowVue },
  setup() {
    const selectedTab = ref(null);
    const postInfos = ref([]);

    const onClickTab = async (id) => {
      const news = await getLatestArticleAPI(id, 5);

      postInfos.value = news;
    };

    const mockTabsData = [
      { id: ARTICLE_TYPE.NEWS.ID, name: ARTICLE_TYPE.NEWS.NAME },
      { id: ARTICLE_TYPE.BEAUTY.ID, name: ARTICLE_TYPE.BEAUTY.NAME },
      { id: ARTICLE_TYPE.COLUMN.ID, name: ARTICLE_TYPE.COLUMN.NAME },
    ];

    const init = () => {
      onClickTab(ARTICLE_TYPE.NEWS.ID);
    };

    init();

    return { mockTabsData, selectedTab, onClickTab, postInfos };
  },
};
</script>

<style lang="scss" scoped>
.latest-article {
  &__main-title {
    @include spreadTypoMap($title-small);

    @include md-and-up {
      @include spreadTypoMap($title-large);
    }
  }

  &__list {
    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.deep__wrapper:deep {
  .custom-tab-group {
    margin-top: 16px;
  }

  .tab:first-of-type {
    margin-inline-start: 0 !important;
  }

  .custom-tab {
    @include spreadTypoMap($caption-medium);
    color: $gray-50;

    @include md-and-up {
      @include spreadTypoMap($button-xLarge);
      color: $gray-50;
    }
  }
}
</style>
