<template>
  <page>
    <template #main-section>
      <intro-background-vue />

      <introduction-row-vue />

      <v-row class="category-card__wrapper" justify="center" tag="section">
        <template v-for="(category, idx) in mockCategoryCards" :key="category.title">
          <category-card-vue
            :title="category.title"
            :desc="category.desc"
            :to="category.to"
            :on-click="category.onClick"
            :translucent="idx % 2 !== 0"
          ></category-card-vue>
        </template>
      </v-row>

      <v-row class="calendar__wrapper" justify="center" tag="section">
        <main-calendar />
      </v-row>

      <Suspense>
        <major-news-vue />
      </Suspense>

      <!--      <search-input-vue />-->

      <v-row class="article__wrapper" offset-lg="1">
        <Suspense>
          <popular-article-vue class="article__item" />
        </Suspense>
        <Suspense>
          <latest-article-vue class="article__item" />
        </Suspense>
      </v-row>

      <!--      <div class="carousel-root">-->
      <!--        <banner-carousels-vue />-->
      <!--      </div>-->

      <!--      리플렛 모달-->
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <header class="modal-header">
            <h2 class="modal-title text__title-small">경기 안내</h2>
            <button type="button" @click="dialog = false"><v-icon :icon="mdiClose" /></button>
          </header>
          <section style="height: 100%; display: flex; justify-content: center; align-items: center">
            <v-carousel show-arrows="hover" hide-delimiters>
              <template v-for="({ src }, idx) in leafletImages" :key="idx">
                <v-carousel-item :src="src" />
              </template>
            </v-carousel>
          </section>
        </v-card>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page';
import CategoryCardVue from '@/components/CategoryCard.vue';
// import SearchInputVue from '@/components/SearchInput.vue';
// import BannerCarouselsVue from '@/components/BannerCarousels.vue';
import { ref, onMounted } from 'vue';

import IntroBackgroundVue from '@/containers/Main/IntroBackground.vue';
import IntroductionRowVue from '@/containers/Main/IntroductionRow.vue';
import MajorNewsVue from '@/containers/Main/MajorNews.vue';
import PopularArticleVue from '@/containers/Main/PopularArticle.vue';
import LatestArticleVue from '@/containers/Main/LatestArticle.vue';
import MainCalendar from '@/containers/Main/MainCalendar';

import { mdiClose } from '@mdi/js';

// import { infoToast } from '@/utils/toast';

export default {
  name: 'HomeView',
  components: {
    Page,
    IntroBackgroundVue,
    IntroductionRowVue,
    // SearchInputVue,
    // BannerCarouselsVue,
    CategoryCardVue,
    MajorNewsVue,
    PopularArticleVue,
    LatestArticleVue,
    MainCalendar,
  },
  setup() {
    const dialog = ref(false);
    const model = ref(null);

    onMounted(() => {
      document.title = '대한미용사회중앙회';
    });

    // const onClickPreparePage = () => {
    //   infoToast('오픈 예정인 페이지입니다.');
    // };

    const mockCategoryCards = [
      // { title: '구인 구직', desc: '구인 구직 정보를 확인하실 수 있습니다.', to: '/community/17' },
      // { title: '중고매매', desc: '중고 매매 정보를 확인하실 수 있습니다.', to: '/community/20' },
      { title: '뉴스', desc: '협회 정보를 확인하실 수 있습니다.', to: '/news/1' },
      { title: '미용회보', desc: '미용회보를 확인하실 수 있습니다.', to: '/regular-report' },
      { title: '칼럼&문화', desc: '칼럼&문화를 확인하실 수 있습니다.', to: '/culture/11' },
      { title: '금융몰', desc: '하나은행과 함께하는 대한미용사회중앙회.', to: '/product-mall' },
    ];

    const leafletImages = [{ src: require('@/assets/leaflet-1.jpg') }, { src: require('@/assets/leaflet-2.jpg') }];

    return {
      mockCategoryCards,
      dialog,
      model,
      mdiClose,
      leafletImages,
    };
  },
};
</script>

<style scoped lang="scss">
.category-card__wrapper:deep {
  margin: 48px 0 0;
  padding: 32px 20px 56px;
  background: $black;

  & > .category-card + .category-card {
    margin-top: 16px;

    @include md-and-up {
      margin-top: 0;
    }
  }

  @include md {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    background: transparent;
  }

  @include lg-and-up {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    background: transparent;
  }
}

.calendar__wrapper {
  padding: 36px 24px 36px;
  background: rgba(10, 10, 10, 0.8);

  @include md-and-up {
    margin: 96px 0;
    padding: 0;
    background: white;
  }
}

.article {
  &__wrapper {
    margin-top: 24px;
    margin-bottom: 116px;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(1, 100%);

    @include lg-and-up {
      margin-top: 96px;
      display: grid;
      grid-template-columns: repeat(2, minmax(400px, calc(50% - 8px)));
      grid-gap: 16px;
    }
  }

  &__item {
    padding: 24px 30px;

    @include lg-and-up {
      padding: 0;
    }
  }
}

.carousel-root {
  padding-top: 24px;
  padding-bottom: 24px;

  @include md-and-up {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
</style>
